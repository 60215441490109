<section id="intro" class="subpage" data-layout="vertical">
    <div class="wrapper" data-layout="horizontal">
        <header>
            <a><h1>Newsroom</h1></a>
        </header>
        <div class="row">
            <a *ngIf="mediumStoriesJson" routerLink="/learn/newsroom" fragment="stories">Stories</a>
            <a *ngIf="localNewsJson" routerLink="/learn/newsroom" fragment="featured">Featured News</a>
            <a routerLink="/learn/company">About Us</a>
            <a button rel="noopener noreferrer" data-variant="arrow" href="https://blog.blocksquare.io/">Blog</a>
        </div>
    </div>

    <ng-template ngFor let-newsPost [ngForOf]="localBigNewsJson">
        <a rel="noopener noreferrer" href="{{newsPost.url}}" class="wrapper img-fixed">
            <article>
                <header>
                    <label>{{newsPost.eyebrowHeadline}}</label>
                    <h1>{{newsPost.mainHeadline}}</h1>
                    <p>{{newsPost.body}}</p>
                    <div class="btn-wrapper">
                        <a button rel="noopener noreferrer" data-variant="arrow" href="{{newsPost.url}}">Read more</a>
                    </div>
                </header>
                <aside>
                    <img src="{{newsPost.imgSrc}}" alt="{{newsPost.mainHeadline}}">
                </aside>
            </article>
        </a>
    </ng-template>
</section>

<section id="stories" *ngIf="localNewsJson && mediumStoriesJson">
    <div class="wrapper">
        <header>
            <h1>Stories from our blog</h1>
            <a button rel="noopener noreferrer" data-variant="arrow" href="https://blog.blocksquare.io">More stories</a>
        </header>

        <div class="wrapper">
            <table aria-describedby="Table of Stories">
                <tr>
                    <th scope="col"></th>
                    <th scope="col"></th>
                </tr>
                <tr *ngFor="let newsPost of mediumStoriesJson">
                    <td>
                        <time>{{ formatDate(newsPost.date)}}</time>
                    </td>
                    <td>
                        <a rel="noopener noreferrer" href="{{newsPost.url}}">{{newsPost.mainHeadline}}</a>
                    </td>
                </tr>
            </table>
        </div>

    </div>
</section>

<section id="featured" *ngIf="localNewsJson">
    <div class="wrapper">
        <header>
            <h1>Featured news</h1>
        </header>
        <div class="wrapper" data-layout="grid">
            <a rel="noopener noreferrer" href="{{newsPost.url}}" class="card no-background" *ngFor="let newsPost of localNewsJson">
                <div class="header">
                    <img src="{{newsPost.imgSrc}}" alt="{{newsPost.mainHeadline}}" data-fit="width">
                </div>
                <div class="features">
                    <label>{{newsPost.eyebrowHeadline}}</label>
                    <h1>{{newsPost.mainHeadline}}</h1>
                    <p>
                        {{newsPost.body}}
                    </p>
                </div>
            </a>
        </div>
    </div>
</section>

<section id="navigation">
    <div class="wrapper">
        <article class="card" routerLink="/learn/company">
            <img src="../../../assets/learn/intro/learn-icons-company.svg" alt="">
            <button data-variant="arrow" class="action">Company</button>
            <div>A reliable team of experts.</div>
        </article>

        <article class="card" routerLink="/learn/tokenization">
            <img src="../assets/learn/propositions/icon-03.svg" alt="">
            <button data-variant="arrow" class="action">Tokenization</button>
            <div>100s of real estate markets suffer from illiquidity.</div>
        </article>
    </div>
</section>
