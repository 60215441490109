import {Component} from '@angular/core';
import {NewsAggregator} from "../../../news/NewsAggregator";
import * as featuredNews from '../../../assets/learn/newsroom/featured-news.json';
import * as mediumLocal from '../../../assets/learn/newsroom/medium-local-copy.json';

@Component({
    selector: 'app-learn-newsroom',
    templateUrl: './newsroom.component.html',
    styleUrls: ['./newsroom.component.scss', '../learn.component.scss', '../../app.component.scss']
})
export class NewsroomComponent {
    localNewsJson: any;
    localBigNewsJson: any;
    mediumStoriesJson: any;

    private mediumJson = 'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/blocksquare';

    constructor() {
        this.localNewsJson = NewsAggregator.getFeaturedNewsJson(featuredNews, false);
        this.localBigNewsJson = NewsAggregator.getFeaturedNewsJson(featuredNews, true);
        this.mediumStoriesJson = NewsAggregator.getFeaturedNewsJson(mediumLocal);
    }

    formatDate(date: string) {
        const dateObject = new Date(date);
        const dayName = dateObject.toLocaleDateString('en-US', {weekday: 'long'});
        const dayNumber = dateObject.getDay()
        const monthName = dateObject.toLocaleDateString('en-US', {month: 'short'});
        const year = dateObject.getFullYear();
        return `${dayName}, ${monthName} ${dayNumber}, ${year}`;
    }
}
