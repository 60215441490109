import * as THREE from 'three';
import {Sphere, Vector3} from 'three';
import * as d3 from "d3";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {isDevMode} from "@angular/core";
import {random} from "gsap/gsap-core";
import {Object} from "./Object";
import * as topojson from "topojson-client";

export class Animation {
    private static timeline: gsap.core.Timeline[] = [];

    static satellites(targetSelector) {
        const target = document.querySelector(targetSelector);
        var scene = new THREE.Scene();
        document.addEventListener('mousemove', onMouseMove, false);
        var camera = new THREE.PerspectiveCamera(75, target.scrollWidth / target.scrollHeight, 0.1, 1000);
        var mouseX;
        var mouseY;

        var renderer = new THREE.WebGLRenderer({alpha: true});
        renderer.setSize(target.scrollWidth, target.scrollHeight);
        target.appendChild(renderer.domElement);

        window.addEventListener("resize", function () {
            camera.aspect = target.scrollWidth / target.scrollHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(target.scrollWidth, target.scrollHeight);
        });

        var distance = 110;
        var geometry = new THREE.Geometry();

        for (var i = 0; i < 1600; i++) {

            var vertex = new THREE.Vector3();

            var theta = THREE.MathUtils.randFloatSpread(360);
            var phi = THREE.MathUtils.randFloatSpread(360);

            vertex.x = distance * Math.sin(theta) * Math.cos(phi);
            vertex.y = distance * Math.sin(theta) * Math.sin(phi);
            vertex.z = distance * Math.cos(theta);

            geometry.vertices.push(vertex);
        }
        var particles = new THREE.Points(geometry, new THREE.PointsMaterial({color: 0x00c0ae, size: 2}));
        particles.geometry.boundingSphere = new Sphere(new Vector3(0, 0, 0), 50);

        var renderingParent = new THREE.Group();
        renderingParent.add(particles);

        var resizeContainer = new THREE.Group();
        resizeContainer.add(renderingParent);
        scene.add(resizeContainer);

        camera.position.z = 400;

        var animate = function () {
            requestAnimationFrame(animate);
            renderer.render(scene, camera);
        };
        var myTween;

        function onMouseMove(event) {
            if (myTween)
                myTween.kill();

            mouseX = (event.clientX / target.scrollWidth) * 2 - 1;
            mouseY = -(event.clientY / target.scrollHeight) * 2 + 1;
            myTween = gsap.to(particles.rotation, {duration: 0.1, x: mouseY * -1, y: mouseX});
        }

        animate();

// Scaling animation
        var animProps = {scale: 1, xRot: 0, yRot: 0};
        gsap.to(animProps, {
            duration: 10, scale: 2, repeat: -1, yoyo: true, ease: "sine", onUpdate: function () {
                renderingParent.scale.set(animProps.scale, animProps.scale, animProps.scale);
            }
        });

        gsap.to(animProps, {
            duration: 120, xRot: Math.PI * 2, yRot: Math.PI * 4, repeat: -1, yoyo: true, ease: "none", onUpdate: function () {
                renderingParent.rotation.set(animProps.xRot, animProps.yRot, 0);
            }
        });
    }

    static globe(targetSelector) {
        var width = 400, height = 400;

        var projection = d3.geoOrthographic()
            .scale(200)
            .translate([width / 2, height / 2])
            .clipAngle(90);

        var path = d3.geoPath()
            .projection(projection);

        var λ = d3.scaleLinear()
            .domain([0, width])
            .range([-180, 180]);

        var φ = d3.scaleLinear()
            .domain([0, height])
            .range([90, -90]);

        var svg = d3.select(targetSelector)
            .attr("width", width)
            .attr("height", height);

        var world = Object.world;

        svg.append("path")
            .datum(topojson.feature(world, world.objects.land))
            .attr("class", "land")
            .attr("d", path);


        var scrollSpeed = 50;
        var current = 0;

        function bgscroll() {
            current += 1;
            projection.rotate([λ(current), 0]);
            svg.selectAll("path").attr("d", path);
        }

        setInterval(bgscroll, scrollSpeed);
    }

    static floating(animate, targetSelector, backgroundSelector) {

        if (!animate) {
            return;
        }


        let randomX = randomInt(10, 20);
        let randomY = randomInt(20, 30);
        let randomDelay = randomInt(0, 1);
        let randomTime = randomInt(3, 5);
        let randomTime2 = randomInt(5, 10);
        let randomAngle = randomInt(8, 12);
        let ease = "sine";

        const background = document.querySelector(backgroundSelector);

        background.addEventListener("mouseover", () => {
            randomX = randomInt(10, 60);
            randomY = randomInt(60, 90);
            randomDelay = () => 0;
            randomTime = randomInt(0.5, 1);
            randomTime2 = randomInt(1, 1.5);
            ease = "none";
        });

        background.addEventListener("mouseleave", () => {
            randomX = randomInt(10, 20);
            randomY = randomInt(20, 30);
            randomDelay = randomInt(0, 1);
            randomTime = randomInt(3, 5);
            randomTime2 = randomInt(5, 10);
            ease = "sine";
        });


        function rotate(target, direction) {

            gsap.to(target, randomTime2(), {
                rotation: randomAngle(direction),
                delay: randomDelay(),
                ease: ease,
                onComplete: rotate,
                onCompleteParams: [target, direction * -1]
            });
        }

        function moveX(target, direction) {

            gsap.to(target, randomTime(), {
                x: randomX(direction),
                ease: ease,
                onComplete: moveX,
                onCompleteParams: [target, direction * -1]
            });
        }

        function moveY(target, direction) {

            gsap.to(target, randomTime(), {
                y: randomY(direction),
                ease: ease,
                onComplete: moveY,
                onCompleteParams: [target, direction * -1]
            });
        }

        function randomInt(min, max) {
            const delta = max - min;
            return (direction = 1) => (min + delta * Math.random()) * direction;
        }

        gsap.registerPlugin(ScrollTrigger);
        gsap.config({nullTargetWarn: false});


        gsap.utils.toArray(targetSelector).forEach((target: Element) => {
            gsap.from(target, {
                opacity: 0,
                delay: random(0, 1, 0.1),
                ease: "power2.inOut",
            });

            gsap.set(target, {
                x: randomX(-1),
                y: randomX(1),
                rotation: randomAngle(-1)
            });

            moveX(target, 1);
            moveY(target, -1);
            rotate(target, 1);

        });

        return gsap;

    }

    static explodedView(animate, targetSelector) {

        if (!animate) {
            return;
        }

        gsap.registerPlugin(ScrollTrigger);
        gsap.config({nullTargetWarn: false});

        let timeline;
        gsap.utils.toArray(targetSelector).forEach((target: Element, i) => {

            timeline = gsap.timeline(
                {
                    scrollTrigger: {
                        trigger: target,
                        id: 'exploded' + (i + 1),
                        start: 'top top+=40%',
                        toggleActions: 'play none none reverse',
                        // markers: isDevMode(),
                    }
                });

            timeline.from(target.querySelectorAll('.dialog.top'), {
                left: '50%',
                top: '50%',
                opacity: 0,
                ease: "circ",
            }, 0);

            timeline.from(target.querySelectorAll('.dialog.middle'), {
                left: '50%',
                opacity: 0,
                ease: "circ",
            }, 0);

            timeline.from(target.querySelectorAll('.dialog.bottom'), {
                left: '50%',
                bottom: '50%',
                opacity: 0,
                ease: "circ",
            }, 0);

            this.timeline.push(timeline);
        });
        return gsap;
    }

    static slidingWindow(animate) {
        if (!animate) {
            return;
        }

        gsap.registerPlugin(ScrollTrigger);
        gsap.defaults({
            ease: "none",
            duration: 0.1,
        })

        let window = document.querySelector('#window') as HTMLInputElement;
        let wrapper = document.querySelector('#stages .wrapper') as HTMLInputElement;

        let timeline = gsap.timeline({
            scrollTrigger: {
                trigger: '#stages',
                id: 'stages',
                start: 'top top+=15%',
                scrub: 0.5,
                snap: "labels",
                pin: true,
                // markers: isDevMode(),
            }
        });

        gsap.to(window, {opacity: 1});

        gsap.utils.toArray('#stages .card:not([id])').forEach((card: HTMLInputElement, i) => {

            timeline.to(window, {
                    x: card.offsetLeft - wrapper.offsetLeft,
                    width: card.offsetWidth,
                }
            );

            if (card.nextSibling) {
                timeline.to(window, {
                        width: card.offsetWidth + (card.nextSibling as HTMLInputElement).offsetWidth,
                    },
                    "width" + i
                );
            }
        });

        this.timeline.push(timeline);
        return gsap;
    }

    static killAllAnimations() {
        ScrollTrigger.getAll().forEach(st => {
            if (isDevMode()) {
                console.debug("RIP ScrollTrigger", st.vars.id);
            }
            st.kill();
        });

        if (this.timeline) {
            this.timeline.forEach(timeline => {
                if (isDevMode()) {
                    console.debug("RIP Timeline", timeline);
                }
                timeline.clear();
                timeline.kill();
            });
            this.timeline = [];
        }
    }

    static refreshScrollTrigger() {
        ScrollTrigger.refresh();
    }
}
