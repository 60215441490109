import { BrowserModule } from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { TokenComponent } from './token/token.component';
import { PricingComponent } from './pricing/pricing.component';
import { ProductsComponent } from './products/products.component';
import { TokenizationProtocolComponent } from './products/tokenization-protocol/tokenization-protocol.component';
import { WhiteLabelPlatformComponent } from "./products/white-label-platform/white-label-platform.component";
import { LearnComponent } from './learn/learn.component';
import {TokenizationComponent} from "./learn/tokenization/tokenization.component";
import {BlockchainComponent} from "./learn/blockchain/blockchain.component";
import {RegulationComponent} from "./learn/regulation/regulation.component";
import {DefiComponent} from "./learn/defi/defi.component";
import {CompanyComponent} from "./learn/company/company.component";
import {NewsroomComponent} from "./learn/newsroom/newsroom.component";
import {IsSecureGuard} from "./guard/IsSecureGuard";
import {DefiBridgeComponent} from "./products/defi-bridge/defi-bridge.component";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    TokenComponent,
    PricingComponent,
    ProductsComponent,
    TokenizationProtocolComponent,
    WhiteLabelPlatformComponent,
    DefiBridgeComponent,
    LearnComponent,
    TokenizationComponent,
    BlockchainComponent,
    RegulationComponent,
    DefiComponent,
    CompanyComponent,
    NewsroomComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [IsSecureGuard],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
