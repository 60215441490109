<section id="intro" data-layout="no-overflow">
    <div class="wrapper" data-layout="horizontal">
        <article>
            <header>
                <h1>Tokenization infrastructure for real estate.</h1>
            </header>
            <p>Businesses of all types — from startups to large enterprises — can use Blocksquare’s solutions and APIs to digitize value of real estate properties, launch their investment platforms and connect people to tokenized real estate deals online.</p>
            <a button rel="noopener noreferrer" href="https://blocksquare.typeform.com/to/BsDXI1">Contact sales</a>
        </article>

        <article class="scheme">
            <section id="turnglobe">
                <div id="worldmap">
                    <svg></svg>
                </div>
            </section>
        </article>


    </div>
</section>

<section id="partners" background="3">
    <div class="wrapper">
        <a rel="noopener noreferrer" href="https://www.fusionpartners.ch"> <img src="assets/home/partners/fusion.png" alt="Partner Fusion logo"/></a>
        <a rel="noopener noreferrer" href="https://www.medius.si"> <img src="assets/home/partners/medius.png" alt="Partner Medius logo"/></a>
        <a rel="noopener noreferrer" href="https://www.deloitte.com"> <img src="assets/home/partners/deloitte.png" alt="Partner Deloitte logo"/></a>
        <a rel="noopener noreferrer" href="https://cvvc.com/cvlabs"> <img src="assets/home/partners/cv-labs.png" alt="Partner CV Labs logo"/></a>
        <a rel="noopener noreferrer" href="https://fibree.org"> <img src="assets/home/partners/fibree.png" alt="Partner Fibree logo"/></a>
        <a rel="noopener noreferrer" href="https://www.builtworld.com"> <img src="assets/home/partners/builtworld.png" alt="Partner Built logo"/></a>
        <a rel="noopener noreferrer" href="https://www.lemur.legal"> <img src="assets/home/partners/lemur-legal.png" alt="Partner Lemur Legal logo"/></a>
    </div>
</section>

<section id="tokenization-protocol">
    <div class="wrapper">
        <article>
            <header>
                <label>Tokenization protocol</label>
                <h2>Digitize the value and revenues of any real estate property.</h2>
            </header>
            <p>With our real estate tokenization protocol entrepreneurs can start digitising real estate assets at a fraction of the cost.
                Built on Ethereum and IPFS, any single real estate property can be converted into 100,000 tokens, either partially or in full, providing investors a transparent and standardised digitalisation process.
                What's more, it's applicable to any common legal ownership form.
            </p>
            <button data-variant="outline" routerLink="/products" fragment="spring">Learn more</button>
        </article>
        <article class="scheme">
            <img src="../../assets/home/digitize/building-to-tokens-3d.svg" alt="Digitize">
        </article>
    </div>
</section>

<section background="4"></section>

<section id="white-label-platform">

    <div class="wrapper">
        <article>
            <header>
                <label>White-label platform</label>
                <h2>Launch your real estate investment portal without coding.</h2>
            </header>
            <p>Designed to power 100s of investment portals across the globe, you can start safely connecting investors to your listed real estate deals.
                With powerful administrative tools to easily on-board and KYC investors, offer users the ability to fully customize their portfolios and trade your listed real estate deals.
            </p>
            <ul list-style="checked">
                <li>Focus on the business — launch in weeks (not months)</li>
                <li>Built for entrepreneurs — no coding required!</li>
                <li>Your language, branding and colors</li>
            </ul>
            <button data-variant="outline" routerLink="/products" fragment="all-stages">Discover the features</button>
        </article>
        <article class="scheme float-right" data-hidden="mobile">
            <div class="platforms">
                <img src="assets/app/color-platforms/color-platforms.004.jpeg" alt="Color platform 4">
                <img src="assets/app/color-platforms/color-platforms.006.jpeg" alt="Color platform 6">
                <img src="assets/app/color-platforms/color-platforms.002.jpeg" alt="Color platform 2">
                <img src="assets/app/color-platforms/color-platforms.008.jpeg" alt="Color platform 8">
            </div>
        </article>
    </div>
</section>

<section id="robust-infrastructure" theme="dark no-bg" class="has-slope">
    <div class="slope" background></div>
    <div class="wrapper diagram" data-layout="horizontal split">
        <header>
            <label>Robust infrastructure</label>
            <h2>Leverage the power of blockchain and decentralisation.</h2>
            <p>Our products rely on established decentralised networks like Ethereum and IPFS. Built using best-in-class frameworks, our applications are designed with scalability in mind, so you can grow your business.</p>
            <button data-variant="outline" routerLink="/products" fragment="all-stages">Explore core product</button>
        </header>
        <section class="scheme">
            <img src="assets/home/roles/3-products-darkbg.svg" alt="Products scheme">
        </section>
    </div>
</section>

<section id="digital-finance">
    <div class="wrapper" data-layout="horizontal">
        <article class="scheme">
            <aside>
                <div class="text"><strong>20%</strong>&nbsp;Common Equity</div>
                <div class="text"><strong>20%</strong>&nbsp;Preferred Equity</div>
                <div class="text"><strong>10%</strong>&nbsp;Tokenization</div>
                <div class="text"><strong>50%</strong>&nbsp;Senior debt</div>
            </aside>
            <img src="assets/home/issuers/building-capital_stack-3d.svg" alt="Tokenized stack of shares of a building"/>
        </article>
        <article>
            <header>
                <label>Digital finance</label>
                <h2>Add a new slice to the traditional capital stack.</h2>
            </header>
            <p>Tokenization offers the ability to match available market demand by reaching investors of any size. A solution that can increase liquidity for a wider selection of players in the commercial real estate investment space like asset managers, investment funds, private investors, real estate developers, banking institutions, financing consultants, online investment platforms, public-private partnerships, family offices and more.</p>
            <ul list-style="checked">
                <li>Add a new valuation layer</li>
                <li>Increase liquidity for your partners</li>
                <li>Expose assets to the digital economy</li>
                <li>Increase the pool of investors</li>
                <li>Real-time transparency and reporting</li>
            </ul>
            <a button data-variant="outline" routerLink="/learn">Learn more</a>
        </article>
    </div>
</section>

<section background="5"></section>

<section id="open-for-collaboration" data-layout="vertical">
    <div class="wrapper">
        <header>
            <label>Open for collaboration</label>
            <h2>Your reliable partner. Globally.</h2>
        </header>

        <div class="wrapper" data-layout="grid icon-list-v">
            <div>
                <img src="../../assets/learn/propositions/icon-09.svg" alt="Proposition icon 9"/>
                <h3>The backbone infrastructure for real estate finance.</h3>
                <p>For ambitious companies around the world, Blocksquare makes investing in real estate assets as simple, borderless, and programmable as the rest of the internet.</p>
                <a button data-variant="outline" routerLink="/learn">Discover our products</a>
            </div>
            <div>
                <img src="../../assets/learn/propositions/icon-01.svg" alt="Proposition icon 1"/>
                <h3>Designed exclusively for real estate assets.</h3>
                <p>Real estate is the biggest asset class in the world and yet extremely hard to access on a global level. It thus requires a purposely built solution, designed to power 100s of investment portals across the globe, connecting investors to deals in the region.</p>
                <a button data-variant="outline" routerLink="/learn">Learn more</a>
            </div>
            <div>
                <img src="../../assets/learn/propositions/icon-08.svg" alt="Proposition icon 9"/>
                <h3>Team of experts covering everything you need.</h3>
                <p>With Blocksquare you get a full team of experts to help you launch in weeks. Our team covers expertise in tech, business, legal and finance, while our partner network can help you find the right solution in your market.</p>
                <a button data-variant="outline" routerLink="/learn">Who we are</a>
            </div>
            <div>
                <img src="../../assets/learn/propositions/icon-02.svg" alt="Proposition icon 2"/>
                <h3>Technology enabler for established companies</h3>
                <p>If you are planning to launch your real estate investment platform, look no further, we have you covered!</p>
                <a button rel="noopener noreferrer" href="https://blocksquare.typeform.com/to/BsDXI1">Contact sales</a>
            </div>
        </div>
    </div>
</section>

<section id="testimonial">
    <article class="card quote">
        <div class="text">The team has deep knowledge and creativity! They are a beacon in the global PropTech scene, shaping the future for real estate investing. Denis and team, please continue the good work!</div>
        <div class="author">
            <div class="name">
                <strong>Jo Bronckers</strong>
                <div>Board member, FIBREE</div>
            </div>
            <img src="assets/home/testimonial/jo.png" alt="A portrait of Jo Bronckers, board member of FIBREE">
        </div>
    </article>
    <article class="card quote">
        <div class="text">Blocksquare is one of Europe's leading companies in the space of tokenization of real estate. Their innovative and focused approach earned them awards and recognition in the real estate industry. This is a company to watch!</div>
        <div class="author">
            <div class="name">
                <strong>Achim Jedelsky</strong>
                <div>President, FIBREE</div>
            </div>
            <img src="assets/home/testimonial/achim.png" alt="A portrait of Achim Jedelsky, president of FIBREE">
        </div>
    </article>
    <article class="card quote">
        <div class="text">Congratulations to Blocksquare for the 100,000 USD prize and best of luck with further scaling the project, which looks very promising. We are looking forward to supporting the project out of the Crypto Valley and to see more tokenized properties soon.</div>
        <div class="author">
            <div class="name">
                <strong>Olaf Hannemann</strong>
                <div>Co-Founder, CV VC</div>
            </div>
            <img src="assets/home/testimonial/olaf.png" alt="A portrait of Olaf Hannemann, co-founder of CV VC">
        </div>
    </article>
</section>
