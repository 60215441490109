<!--suppress HtmlUnknownTarget -->
<section id="intro" background="3">
    <div class="wrapper" data-layout="big">
        <article>
            <header>
                <label>Blocksquare Token</label>
                <h1>Shape the future of real estate.</h1>
            </header>
            <p>The Blocksquare Token BST is the governance token that serves as the basis for the <a link rel="noopener noreferrer" href="https://oceanpoint.fi/">Oceanpoint</a> ecosystem. BST holders To vote on proposals posted on <a link rel="noopener noreferrer" href="https://snapshot.org/#/blocksquare.eth">Snapshot</a>, BST holders need to first deposit and stake their tokens in the Governance pool. Staking also enables BST holders to passively accumulate more BST as the protocol grows.</p>
            <button data-variant="outline" routerLink="/token" fragment="what-is-bst">Learn more</button>
        </article>
        <article class="tiles">
            <div class="card">
                <img src="../assets/token/logo/gate-io.svg" alt="Gate.io Logo as part of the Token intro graphics">
            </div>
            <div class="card">
                <img src="../assets/token/logo/bitmart.svg" alt="Bitmart Logo as part of the Token intro graphics">
            </div>
            <div class="card">
                <img src="../assets/token/logo/1inch.svg" alt="1 Inch Logo as part of the Token intro graphics">
            </div>
            <div class="card">
                <img src="../assets/token/logo/uniswap.svg" alt="Uniswap Logo as part of the Token intro graphics">
            </div>
            <div class="card">
                <img src="../assets/token/logo/mexc.svg" alt="MEXC Logo as part of the Token intro graphics">
            </div>
        </article>
    </div>
</section>

<section id="what-is-bst">
    <div class="wrapper">
        <article>
            <header>
                <label>Protocol governance</label>
                <h2>What is BST token?</h2>
            </header>
            <p>
                BST is a utility token powering Blocksquare's ecosystem.
                When staked via Oceanpoint, Blocksquare's DeFi protocol, it awards holders with Staked BST (sBST) — a token used by the community to vote on Oceanpoint related decisions.
            </p>
            <p>
                To vote on proposals posted on <a link rel="noopener noreferrer" href="https://snapshot.org/#/blocksquare.eth">Shapshot</a>, BST holders need to first deposit and stake their tokens in the <a link rel="noopener noreferrer" href="https://app.oceanpoint.fi">Governance pool</a>.
                Staking also enables BST holders to passively accumulate more BST as the protocol grows.
            </p>
        </article>
        <article class="scheme">
            <img src="../assets/token/discord.svg" alt="The Discord logo on the stylized background, including Oceanpoint and Discord colors. ">
            <a button rel="noopener noreferrer" href="https://go.blocksquare.io/discord-invite">Join us on Discord</a>
        </article>
    </div>
</section>

<section id="bst-metrics" theme="dark no-bg" class="has-slope">
    <div class="slope" background></div>
    <div class="wrapper diagram" data-layout="horizontal split">
        <header>
            <label>Token metrics</label>
            <h2>Allocation chart for native BST token</h2>
        </header>
        <section class="scheme">
            <img src="../../assets/token/bst-distribution.svg" alt="A Pie-chart of the Blocksquare Token Distribution. 40% goes to rewards, 22% to the Team, 15% is for investors, 10% for pre-ICO, 5% for marketing, again 5% for Liquidity provisioning and 3% for our mentors.">
        </section>
    </div>
</section>

<section id="bst-buybacks">
    <div class="wrapper">
        <article>
            <header>
                <label>Tokenomics</label>
                <h2>BST buybacks</h2>
            </header>
            <p>
                Once real estate owners start staking in the Asset pool, their real estate asset tokens i.e. BSPT become part of Oceanpoint, and after the 6 months lock-up period, Blocksquare shall exercise the legal right to revenues generated by these real estate assets.
                BSPT tokens owned by Oceanpoint will thus accrue DAI used to buy BST on the market to be distributed to active users of Oceanpoint.
            </p>
        </article>
        <article class="scheme">
            <img src="../../assets/token/bst-buybacks/BSPT-AMG-1.png" alt="A screenshot of Oceanpoint marketplace for the property Private Office, CBC building in Dubai">
            <img src="../../assets/token/bst-buybacks/BSPT-OCN-2.png" alt="A screenshot of Oceanpoint marketplace for the property Guesthouse Aubrey in Bruges">
            <img src="../../assets/token/bst-buybacks/BSPT-OCN-13.png" alt="A screenshot of Oceanpoint marketplace for the property Rental apartment on Untere Bahnlände 11 in Vienna">
        </article>
    </div>
</section>

<section id="what-is-sbst">
    <div class="wrapper">
        <article>
            <header>
                <label>Staking rewards</label>
                <h2>What is sBST token?</h2>
            </header>
            <p>
                sBST can be obtained by participating in the Governance pool on Oceanpoint, a revenue-sharing mechanism that rewards BST holders with additional BST when they deposit their tokens in it.
                By staking BST, you will get sBST, a tokenized share of the Governance pool that enables you to vote on protocol proposals.
            </p>
        </article>
        <article class="scheme">
            <img src="../../assets/token/what-is-sbst/op-svg-render.svg" alt="A simplified render of the Oceanpoint user interface">
            <div class="actions">
                <a button rel="noopener noreferrer" href="https://app.oceanpoint.fi/">Start staking</a>
                <a button rel="noopener noreferrer" class="uniswap" href="https://go.blocksquare.io/uniswap">Buy bst</a>
            </div>
        </article>
    </div>
</section>

<section id="discounts">
    <div class="wrapper">
        <article>
            <header>
                <label>Partnership program</label>
                <h2>Discounts for marketplace partners</h2>
            </header>
            <p>
                The Blocksquare Token is a digital asset that aligns incentives and identifies clients who share our vision and long term goals.
                For this reason, clients who stake BST in the Governance pool found on Oceanpoint are eligible for some fine discounts when becoming a marketplace operator i.e. <a link rel="noopener noreferrer" href="https://docs.blocksquare.io/certified-partners/partner-program">Certified Partner</a>.
            </p>
            <a button rel="noopener noreferrer" href="https://docs.blocksquare.io/certified-partners/partner-program">Learn more</a>
        </article>
        <article class="scheme">
            <img src="../../assets/discounts/percentage.svg" alt="The percentage sign as a section decoration">
        </article>
    </div>
</section>

<section id="trade">
    <div class="wrapper">
        <article>
            <header>
                <label>Trade BST</label>
                <h2>Where to buy and sell BST?</h2>
            </header>

            <div class="actions">
                <a button rel="noopener noreferrer" href="https://go.blocksquare.io/gate"><img src="../../assets/token/trade/gate-io.svg" alt="GATE.IO logo" />Gate.io</a>
                <a button rel="noopener noreferrer" href="https://go.blocksquare.io/1inch"><img src="../../assets/token/trade/1inch.svg" alt="1inch logo"/>1inch</a>
                <a button rel="noopener noreferrer" href="https://go.blocksquare.io/uniswap"><img src="../../assets/token/trade/uniswap.svg" alt="Uniswap logo" />Uniswap</a>
                <a button rel="noopener noreferrer" href="https://go.blocksquare.io/mexc"><img src="../../assets/token/trade/mexc.svg" alt="MEXC logo" />MEXC</a>
                <a button rel="noopener noreferrer" href="https://go.blocksquare.io/bitmart"><img src="../../assets/token/trade/bitmart.png" alt="Bitmart logo" />Bitmart</a>
                <a button rel="noopener noreferrer" href="https://go.blocksquare.io/coinmetro"><img src="../../assets/token/trade/coinmetro.png" alt="Coinmetro logo" />Coinmetro</a>
                <a button rel="noopener noreferrer" href="https://go.blocksquare.io/bitpanda"><img src="../../assets/token/trade/bitpanda.png" alt="Bitpanda logo" />Bitpanda</a>
            </div>

            <p>
                <small>
                    Blocksquare currently provides OTC solutions for amounts US$50k+.
                    Please contact future@blocksquare.io for details.
                </small>
            </p>
        </article>
        <article class="scheme">
            <div class="coinmarketcap-currency-widget" data-currencyid="3035" data-base="USD" data-secondary="" data-ticker="true" data-rank="true" data-marketcap="false" data-volume="true" data-statsticker="true" data-stats="USD"></div>
        </article>
    </div>
</section>

<section id="where-to-stake">
    <div class="wrapper">
        <article>
            <header>
                <label>Stake and earn</label>
                <h2>Earning BST with Oceanpoint</h2>
            </header>
            <p>
                40% out of the 100M BST total supply have been allocated for liquidity mining. Although this initially set amount to incentivise the early community could be changed through a governance vote, the distribution schedule is currently set to:
            </p>

            <ul>
                <li>1,000,000 BST / month for the initial 3 months after launch</li>
                <li>500,000 BST / month until the 40M BST is reached (approx. 6 years)</li>
            </ul>

            <p>
                <small>Liquidity mining is a mechanism or process in which participants supply cryptocurrencies into liquidity pools, and are rewarded with fees and tokens based on their share.</small>
            </p>

        </article>
        <article class="scheme">
            <img src="../../assets/token/Oceanpoint-token_flow.png" alt="A Chart of the Blocksquare Token Flow">
        </article>
    </div>
</section>
