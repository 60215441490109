<section id="intro" class="intro-background subpage" data-layout="no-overflow">
    <div class="wrapper" data-layout="horizontal">
        <article>
            <header>
                <label>Regulatory advancements</label>
                <h1>Europe pushing towards a digital financial future.</h1>
            </header>
            <p>Crypto-assets are one of the major applications of blockchain technology in finance. Since the publication of the Commission’s Fintech Action plan 5, in March 2018, the Commission has been examining the opportunities and challenges raised by crypto-assets.</p>
            <button data-variant="outline" routerLink="/learn/blockchain" fragment="blockchain">Learn more</button>
        </article>

        <article class="scheme">
            <img src="assets/learn/learn-regulation.svg" alt="Learn regulation">
        </article>
    </div>
</section>

<section id="regulation">
    <div class="wrapper" data-layout="horizontal">
        <article>
        </article>
        <article>
            <header>
                <label>Financial regulation</label>
                <h2></h2>
            </header>
            <p>Tokenization offers the ability to match available market demand by reaching investors of any size. A solution that can increase liquidity for a wider selection of players in the commercial real estate investment space like asset managers, investment funds, private investors, real estate developers, banking institutions, financing consultants, online investment platforms, public-private partnerships, family offices and more.</p>
            <ul list-style="checked">
                <li>Add a new valuation layer</li>
                <li>Increase liquidity for your partners</li>
                <li>Expose assets to the digital economy</li>
                <li>Increase the pool of investors</li>
                <li>Real-time transparency and reporting</li>
            </ul>
            <a button rel="noopener noreferrer" data-variant="outline" routerLink="/learn">Learn more</a>
        </article>
    </div>
</section>

<section id="navigation">
    <div class="wrapper">
        <article class="card" routerLink="/learn/blockchain">
            <img src="../../../assets/learn/intro/learn-icons-blockchain.svg" alt="">
            <button data-variant="arrow" class="action">Blockchain</button>
            <div>Decentralized trust layer for the online economy.</div>
        </article>

        <article class="card" routerLink="/learn/defi">
            <img src="../../../assets/learn/intro/learn-icons-defi.svg" alt="">
            <button data-variant="arrow" class="action">DeFi</button>
            <div>DeFi header</div>
        </article>
    </div>
</section>
