import {Component} from '@angular/core';

@Component({
    selector: 'app-learn-company',
    templateUrl: './company.component.html',
    styleUrls: ['../learn.component.scss', '../../app.component.scss']
})
export class CompanyComponent {

    constructor() {
    }
}
