import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from "./home/home.component";
import {TokenComponent} from "./token/token.component";
import {PricingComponent} from "./pricing/pricing.component";
import {ProductsComponent} from "./products/products.component";
import {TokenizationProtocolComponent} from "./products/tokenization-protocol/tokenization-protocol.component";
import {WhiteLabelPlatformComponent} from "./products/white-label-platform/white-label-platform.component";
import {LearnComponent} from "./learn/learn.component";
import {TokenizationComponent} from "./learn/tokenization/tokenization.component";
import {BlockchainComponent} from "./learn/blockchain/blockchain.component";
import {RegulationComponent} from "./learn/regulation/regulation.component";
import {DefiComponent} from "./learn/defi/defi.component";
import {CompanyComponent} from "./learn/company/company.component";
import {NewsroomComponent} from "./learn/newsroom/newsroom.component";
import {IsSecureGuard} from "./guard/IsSecureGuard";
import {DefiBridgeComponent} from "./products/defi-bridge/defi-bridge.component";

const routes: Routes = [
  {path: '', component: HomeComponent, canActivate: [IsSecureGuard]},
  {path: 'home', component: HomeComponent, canActivate: [IsSecureGuard]},

  {path: 'token', component: TokenComponent, canActivate: [IsSecureGuard]},

  {path: 'products', component: ProductsComponent, canActivate: [IsSecureGuard]},
  {path: 'products/tokenization-protocol', component: TokenizationProtocolComponent, canActivate: [IsSecureGuard]},
  {path: 'products/white-label-platform', component: WhiteLabelPlatformComponent, canActivate: [IsSecureGuard]},
  {path: 'products/defi-bridge', component: DefiBridgeComponent, canActivate: [IsSecureGuard]},

  {path: 'pricing', component: PricingComponent, canActivate: [IsSecureGuard]},

  {path: 'learn', component: LearnComponent, canActivate: [IsSecureGuard]},
  {path: 'learn/tokenization', component: TokenizationComponent, canActivate: [IsSecureGuard]},
  {path: 'learn/blockchain', component: BlockchainComponent, canActivate: [IsSecureGuard]},
  {path: 'learn/regulation', component: RegulationComponent, canActivate: [IsSecureGuard]},
  {path: 'learn/defi', component: DefiComponent, canActivate: [IsSecureGuard]},
  {path: 'learn/company', component: CompanyComponent, canActivate: [IsSecureGuard]},
  {path: 'learn/newsroom', component: NewsroomComponent, canActivate: [IsSecureGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
