<section id="intro" class="intro-background subpage" data-layout="no-overflow">
    <div class="wrapper" data-layout="horizontal">
        <article>
            <header>
                <label>A macro-economic problem</label>
                <h1>100s of real estate markets suffer from illiquidity.</h1>
            </header>
            <p>Blocksquare's infrastructure solutions help businesses like yours launch online investment portals, increasing liquidity for real estate assets located in inefficient markets.</p>
            <button data-variant="outline" routerLink="/learn/tokenization" fragment="tokenization">Learn more</button>
        </article>

        <article class="scheme" data-hidden="mobile">
            <img src="assets/learn/increase-liquidity-nobg.svg" alt="Increase liquidity">
        </article>
    </div>
</section>

<section id="tokenization">
    <div class="wrapper" data-layout="top">
        <header>
            <label>Asset tokenization</label>
            <h2>Match available market demand by reaching investors of any size.</h2>
        </header>
        <article>
            <p>Tokenization is changing how we invest in real estate. Taking us from a world where a property is transected once a decade, to a new reality where hundreds of transactions of that same real estate asset are executed within the minute!</p>
        </article>
        <article>
            <ul list-style="checked">
                <li>diversification</li>
                <li>customizable</li>
                <li>fully digital</li>
                <li>lower fees</li>
                <li>no lock-up periods</li>
                <li>higher liquidity</li>
            </ul>
        </article>
    </div>

    <div class="wrapper">
        <div class="progress-image">
            <img src="assets/learn/testimonial/randy-fath-I0C1zdto_YA-unsplash.jpg" alt="Horse Carriage">
            <img src="assets/learn/testimonial/denys-nevozhai-7nrsVjvALnA-unsplash%20(1).jpg" alt="Big highway crossing">
        </div>
    </div>

    <div class="wrapper" data-layout="top">
        <article>
            <p>Real estate is the biggest asset class in the world. At the same time it’s also one of the most illiquid investments you can make. As a result, investors engage only in the top markets, leaving 100s of smaller markets suffering from illiquidity.</p>
            <p>Tokenization offers the ability to match available market demand by reaching investors of any size. A solution that can increase liquidity for a wider selection of players in the commercial real estate investment space.</p>
            <a button rel="noopener noreferrer" href="https://blocksquare.typeform.com/to/BsDXI1">Contact us</a>
        </article>
        <article>
            <p>Companies of various types like asset managers, investment funds, private investors, real estate developers, banking institutions, financing consultants, online investment platforms, public-private partnerships, family offices and more.</p>
            <ul list-style="checked">
                <li>transparency</li>
                <li>traceability</li>
                <li>investor control</li>
                <li>decentralized</li>
                <li>automation</li>
                <li>borderless</li>
            </ul>
        </article>
    </div>
</section>

<section id="revenue-based-tokenization" background="1">
    <div class="wrapper" data-layout="top">
        <header>
            <label>Innovative technology</label>
            <h2>Revenue-based asset tokenization.</h2>
        </header>
        <article>
            <p>Tokenizing either debt or equity of the capital stack presents challenges — debt is already serviced very well traditionally, while equity tokens have no real on-chain settlement in most jurisdictions around the world.</p>
            <p>Is there anything else out there? A solution that would offer both asset owners and investors something new?</p>
            <p>In the real estate context it is fairly easy to estimate the revenue a particular property can generate. Most common revenue streams for real estate properties are:</p>
            <ul list-style="checked">
                <li>cash flows from lease contracts, and</li>
                <li>sale of the property to a 3rd party buyer</li>
            </ul>
            <p>In terms of commercial real estate, the revenues a property generates also highly impact the valuation of the property as investors look predominantly for a target return.</p>
            <p>Revenue based investing is not by any means a new concept, and is mostly used for financing companies through royalties. A royalty is a defined share of the royalty issuer’s revenues.</p>
        </article>
        <article>
            <p>Royalties have been used for hundreds of years where one party wished to share revenues with another party who was doing something useful to the royalty payer or issuer. Royalties were traditionally used in commerce where the definition of profit was either difficult to determine or not chosen to be revealed.</p>
            <p>To put it simply, the issuer is telling investors that every month they will receive X% of the issuer’s revenues until they receive Y times their cash back and the issuer expects it to be happening in a period of Z years.</p>
            <p>The advantages over buying shares or lending a company money? An investor is not concerned with or impacted by the issuer’s profitability for so long as the issuer meets royalty payment obligations. The royalty owner also benefits when the revenues of an issuer increase, but on the flip side there is also the risk of revenues declining.</p>
            <p>But don’t take our word for it — Investment banker, financier, management consultant, author, lecturer, and inventor of the Lipper Index, Dr. Arthur Lipper, is the main advocate for revenue based investing. Feel free to Google him.</p>
        </article>
    </div>
</section>

<section id="all-products-features" data-layout="vertical">
    <div class="wrapper">
        <header>
            <label>Investing in revenues</label>
            <h2>Benefits of tokenizing top-line revenues.</h2>
        </header>

        <article>
            <p>Revenues are found on the top line of a balance sheet and are easier to understand and project than the profits we find on the bottom line of the balance sheet. Grasping one set of factors that affect revenues is easier than grasping the multi-dimensional factors that affect profit, so risk and reward of revenue investing can be quantified in a more straightforward manner. Therefore, raising capital may become easier, and the decision to green-light the investment becomes more straightforward. Everyone benefits.</p>
        </article>

        <div class="wrapper" data-layout="grid icon-list-h">
            <div>
                <img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>
                <h4>Revenues are easy to understand and project.</h4>
            </div>
            <div>
                <img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>
                <h4>Bottom-line profits can be easily manipulated.</h4>
            </div>
            <div>
                <img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>
                <h4>Quickly quantify investor risk and reward.</h4>
            </div>
            <div>
                <img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>
                <h4>Provides a greater degree of certainty to the investor.</h4>
            </div>
            <div>
                <img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>
                <h4>Real time settlement when trades are executed.</h4>
            </div>
            <div>
                <img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>
                <h4>No requirements for notarization.</h4>
            </div>
            <div>
                <img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>
                <h4>Easier financial reporting for issuers.</h4>
            </div>
            <div>
                <img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>
                <h4>Increased transparency for investors.</h4>
            </div>
            <div>
                <img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>
                <h4>Lower issuance costs as opposed to debt or equity.</h4>
            </div>
            <div>
                <img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>
                <h4>Clear investment terms and conditions.</h4>
            </div>
            <div>
                <img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>
                <h4>No hidden hard to understand contractual clauses.</h4>
            </div>
            <div>
                <img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>
                <h4>Easier to manage and oversee larger portfolios.</h4>
            </div>
        </div>
    </div>
</section>

<section id="navigation">
    <div class="wrapper">
        <article class="card" routerLink="/learn/newsroom">
            <img src="../../../assets/learn/intro/learn-icons-newsroom.svg" alt="">
            <button data-variant="arrow" class="action">Newsroom</button>
            <div>All announcements, updates and new releases in one place.</div>
        </article>

        <article class="card" routerLink="/learn/blockchain">
            <img src="../../../assets/learn/intro/learn-icons-blockchain.svg" alt="">
            <button data-variant="arrow" class="action">Blockchain</button>
            <div>Decentralized trust layer for the online economy.</div>
        </article>
    </div>
</section>
