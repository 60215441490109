<section id="intro" class="subpage" data-layout="no-overflow">
    <div class="wrapper" data-layout="horizontal">
        <article>
            <header>
                <label>The company</label>
                <h1>A reliable team of experts.</h1>
            </header>
            <p>Blocksquare is a technology company building the required infrastructure to transfer real estate assets to the internet. Businesses of every size—from new startups to public companies—use our blockchain infrastructure to offer and manage their real estate investments online.</p>
            <button data-variant="outline" routerLink="/learn/company" fragment="company">Learn more</button>
        </article>

        <article class="scheme" data-hidden="mobile">
            <img src="assets/learn/Blocksquare-Word_Art.svg" alt="Learn company">
        </article>
    </div>
</section>

<section id="company" background="3">
    <div class="wrapper" data-layout="top">
        <header>
            <label>Our vision</label>
            <h2>Real estate investing should be as easy as shopping online.</h2>
        </header>
        <article>
            <p>Blocksquare is an award-winning team developing the world’s leading blockchain-based real estate tokenization system. Blocksquare's vision is to power 100s of platforms across the globe, connecting investors to real estate opportunities in their region.</p>
            <p>Headquartered in Ljubljana, Slovenia (EU), we are developing a state-of-the-art system in close co-operation with Medius — the leading provider of enterprise software solutions in the region.</p>
            <p>With our real estate tokenization protocol entrepreneurs can start digitising real estate assets at a fraction of the cost, while our white-label platform offers the quickest way to launch an investment marketplace.</p>
        </article>
        <article>
            <img src="assets/learn/blocksquare-winner-cv_competition.jpeg" class="rounded" alt="Blocksquare winners">
        </article>
    </div>
</section>

<section id="founding-team" data-layout="vertical">
    <div class="wrapper">
        <header>
            <label>Founding team</label>
            <h2>Multi-disciplinary and experienced team of entrepreneurs.</h2>
        </header>
        <article>
            <p>Denis Petrovcic, Viktor Brajak and Peter Merc, our three co-founders, bring entrepreneurial, tech and legal experience to the project. Overall, we are a team of dedicated professionals, experienced IT developers, versatile entrepreneurs, digital currency believers, real estate investors and blockchain professionals on a mission to make real estate investing easy, safe and available to everyone, everywhere.</p>
            <p>Each of the three co-founders has their unique expertise: Denis Petrovcic is the CEO and mastermind behind all aspects of the company, from ideation and product to organisation and business development. Our CTO Viktor Brajak leads the team of highly capable IT developers making sure our infrastructure is implemented according to the highest standards. While Peter Merc, our Chief Compliance Officer, brings vast legal and regulatory knowledge around fintech, blockchain and real world asset tokenization.</p>
        </article>

        <div class="persons">
            <div class="card person">
                <img src="../../../assets/learn/testimonial/denis.jpg" alt="A portrait of Denis Petrovcic, co-founder & CEO of Blocksquare">
                <label>Denis Petrovčič</label>
                <p>Co-Founder & CEO</p>
                <div class="social">
                    <a rel="noopener noreferrer" href="https://www.linkedin.com/in/denispetrovcic" class="icon-linkedin"></a>
                    <a rel="noopener noreferrer" href="https://twitter.com/dp_blocksquare" class="icon-twitter"></a>
                    <a rel="noopener noreferrer" href="https://medium.com/@denispetrovcic" class="icon-medium"></a>
                </div>
            </div>
            <div class="card person">
                <img src="../../../assets/learn/company/viktor.png" alt="A portrait of Viktor Brajak, co-founder & CTO of Blocksquare">
                <label>Viktor Brajak</label>
                <p>Co-Founder & CTO</p>
                <div class="social">
                    <a rel="noopener noreferrer" href="https://www.linkedin.com/in/viktorbrajak/" class="icon-linkedin"></a>
                    <a rel="noopener noreferrer" href="https://twitter.com/ViktorBrajak" class="icon-twitter"></a>
                    <a rel="noopener noreferrer" href="https://medium.com/@viktor.brajak" class="icon-medium"></a>

                </div>
            </div>
            <div class="card person">
                <img src="../../../assets/learn/company/peter.png" alt="A portrait of Denis Petrovcic, co-founder & CEO of Blocksquare">
                <label>Peter Merc</label>
                <p>Co-Founder & CCO</p>
                <div class="social">
                    <a rel="noopener noreferrer" href="https://www.linkedin.com/in/peter-merc-ph-d-550b212/" class="icon-linkedin"></a>
                    <a rel="noopener noreferrer" href="https://twitter.com/pepinjo" class="icon-twitter"></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="navigation">
    <div class="wrapper">
<!--        <article class="card" routerLink="/learn/defi">-->
<!--            <img src="../../../assets/learn/intro/learn-icons-defi.svg" alt="">-->
<!--            <label>DeFi</label>-->
<!--            <div>DeFi header</div>-->
<!--        </article>-->
        <article class="card" routerLink="/learn/blockchain">
            <img src="../../../assets/learn/intro/learn-icons-blockchain.svg" alt="">
            <button data-variant="arrow" class="action">Blockchain</button>
            <div>Decentralized trust layer for the online economy.</div>
        </article>

        <article class="card" routerLink="/learn/newsroom">
            <img src="../../../assets/learn/intro/learn-icons-newsroom.svg" alt="">
            <button data-variant="arrow" class="action">Newsroom</button>
            <div>All announcements, updates and new releases in one place.</div>
        </article>
    </div>
</section>
