<section id="intro" background="2">
    <div class="wrapper" data-layout="horizontal">
        <article>
            <header>
                <label>White-label platform</label>
                <h1>Launch in weeks without coding.</h1>
            </header>
            <p>Start connecting investors to real estate investment deals in your region. An out-of-the-box solution for entrepreneurs looking to expand their business online. From invite only investment clubs to nation-wide investment portals — we got you covered.</p>
            <button data-variant="outline" routerLink="/products/stage2" fragment="delta">Learn more</button>
        </article>
        <article class="scheme stage3">
            <img src="assets/app/color-platforms/blocksquare.001.jpeg" alt="Blocksquare platform">

            <div class="tiles">
                <div class="bubble">
                    <img src="../assets/home/intro/Dai-logo.png" alt="DAI as part of the Blocksquare platform">
                </div>
                <div class="bubble">
                    <img src="../assets/home/intro/Ipfs-logo-1024-ice-text.png" alt="IPFS as part of the Blocksquare platform">
                </div>
                <div class="bubble">
                    <img src="../assets/home/intro/metamask-logo.png" alt="Metamask as part of the Blocksquare platform">
                </div>
                <div class="bubble">
                    <img src="../assets/home/intro/Ethereum-icon-purple.svg" alt="Ethereum as part of the Blocksquare platform">
                </div>
                <div class="bubble">
                    <img src="../assets/home/intro/React-icon.svg" alt="React as part of the Blocksquare platform">
                </div>
                <div class="bubble">
                    <img src="../assets/home/intro/wallet-connect-logo.svg" alt="WalletConnect as part of the Blocksquare platform">
                </div>
                <div class="bubble">
                    <img src="../assets/home/intro/zs-logo-2.svg" alt="ZignSec as part of the Blocksquare platform">
                </div>
            </div>
        </article>
    </div>
</section>

<section id="delta">

    <div class="wrapper">
        <article>
            <div class="bubble">
                <img src="../assets/products/water-icons-02_delta.svg" alt="Stage 2 Delta Logo">
                <span>Offer & Distribute</span>
                <label>Delta</label>
            </div>
            <header>
                <h2>Safely onboard investors and list tokenized real estate.</h2>
            </header>
            <p>Launching your own tokenized marketplace where investors can log-on, browse through opportunities and execute investments is hard work. Not to mention the time and money required to create a fully functional blockchain product. Blocksquare's platform is designed to power 100s of platforms across the globe, offering investors a seamless experience.
            </p>
            <ul list-style="checked">
                <li>Your branding, colors and language</li>
                <li>List your tokenized real estate deals</li>
                <li>Compatible with any stablecoin</li>
            </ul>
            <a button rel="noopener noreferrer" href="https://blocksquare.typeform.com/to/BsDXI1">Get in touch</a>

        </article>
        <article class="card separated">
            <div class="stage">White-label platform</div>
            <div class="header">
                <div class="icon">
                    <img src="../assets/products/water-icons-02_delta.svg" alt="Stage 2 Delta Logo">
                </div>
                <h3>Delta</h3>
            </div>
            <div class="features">
                <span class="title">Offer & Distribute</span>
                <p>List and sell real estate tokens to investors.</p>
            </div>
            <div class="price">
                <button data-variant="outline" routerLink="/pricing" fragment="stages">See pricing</button>
            </div>
        </article>
    </div>
</section>

<section id="platform2" data-layout="big" background="5">
    <div class="exploded">
        <img class="screen" src="../assets/products/stage2/delta-screen-brandnew_investments.jpeg" alt="Platform portfolio screenshot" (load)="onImgLoad()">
        <img class="dialog left middle" src="../assets/products/stage2/delta-property_card-01.png" alt="">
        <img class="dialog center top " src="../assets/products/stage2/delta-property_card-02.png" alt="">
        <img class="dialog right middle" src="../assets/products/stage2/delta-property_details.png" alt="">
    </div>
</section>

<section id="all-products-features" data-layout="vertical" theme="dark no-bg" class="has-slope">
    <div class="slope" background></div>
    <div class="wrapper">
        <header>
            <label>A comprehensive platform</label>
            <h2>Features your customers will love.</h2>
        </header>
        <p>Blocksquare’s platform provides our clients with a white-label solution to create, list, issue, sell, distribute, manage, track and trade properties tokenized through Blocksquare's protocol. The platform built on top of our API is packed with features required to launch a full size investment marketplace.</p>

        <div class="wrapper" data-layout="grid icon-list-h" >
            <div>
                <img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>
                <h4>List your current, past and future tokenized properties.</h4>
            </div>
            <div>
                <img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>
                <h4>Easily onboard your investors with KYC automation.</h4>
            </div>
            <div>
                <img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>
                <h4>User's can add bank accounts to make EUR investments.</h4>
            </div>
            <div>
                <img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>
                <h4>Investor can create custom token portfolios.</h4>
            </div>
            <div>
                <img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>
                <h4>Buy or sell real estate tokens peer-to-peer.</h4>
            </div>
            <div>
                <img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>
                <h4>Distribute revenue payouts to 1000s of token investors.</h4>
            </div>
            <div>
                <img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>
                <h4>Display all relevant information for each property.</h4>
            </div>
            <div>
                <img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>
                <h4>Transaction history verifiable on a public blockchain.</h4>
            </div>
            <div>
                <img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>
                <h4>Compatible with selected "self-custodian" wallets.</h4>
            </div>
            <div>
                <img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>
                <h4>Register up to 10 digital wallets per account.</h4>
            </div>
            <div>
                <img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>
                <h4>Organize and execute initial real estate token offerings.</h4>
            </div>
            <div>
                <img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>
                <h4>Sell tokens to investors on your platform.</h4>
            </div>
        </div>
    </div>
</section>

<section id="platform4" data-layout="big">
    <div class="exploded">
        <img class="screen" src="../assets/products/stage2/delta-screen-property_puzzle.jpeg" alt="Platform portfolio screenshot" (load)="onImgLoad()">
        <img class="dialog left top" src="../assets/products/stage2/delta-add_bank.png" alt="">
        <img class="dialog right middle" src="../assets/products/stage2/delta-add_funds.png" alt="">
        <img class="dialog left bottom" src="../assets/products/stage2/delta-select_wallet.png" alt="">
    </div>
</section>

<section id="tide" background="1">

    <div class="wrapper">
        <article>
            <div class="bubble">
                <img src="../assets/products/water-icons-03_tide.svg" alt="Stage 3 Tide Logo">
                <span>Monitor & Report</span>
                <label>Tide</label>
            </div>
            <header>
                <h2>Asset trading and revenue distributions.</h2>
            </header>
            <p>Offer your investors to buy or sell real estate tokens online, while you can easily distribute revenues to 1000s of token holders. Blockchain technology enhances transaction transparency and enables investors to trade peer-to-peer without middleman.
            </p>
            <ul list-style="checked">
                <li>Upload and share investor reports</li>
                <li>Peer-to-peer secondary market</li>
                <li>Distribute revenues with ease</li>
            </ul>
            <a button rel="noopener noreferrer" href="https://blocksquare.typeform.com/to/BsDXI1">Get in touch</a>

        </article>
        <article class="card separated">
            <div class="stage">White-label platform</div>
            <div class="header">
                <div class="icon">
                    <img src="../assets/products/water-icons-03_tide.svg" alt="Stage 3 Tide Logo">
                </div>
                <h3>Tide</h3>
            </div>
            <div class="features">
                <span class="title">Monitor & Report</span>
                <p>Monitor trading and payout revenues to token holders.</p>
            </div>
            <div class="price">
                <button data-variant="outline" routerLink="/pricing" fragment="stages">See pricing</button>
            </div>
        </article>
    </div>
</section>

<section id="platform3">
    <div class="exploded">
        <img class="screen" src="../assets/products/stage3/tide-screen-property_market.jpeg" alt="Platform portfolio screenshot" (load)="onImgLoad()">
        <img class="dialog middle right" src="../assets/products/stage3/tide-get_profits.png" alt="">
        <img class="dialog middle left" src="../assets/products/stage3/tide-sell_BSPT.png" alt="">
        <img class="dialog center top" src="../assets/products/stage3/tide-property_buysell.png" alt="">
    </div>
</section>

<section id="wave" background="3">

    <div class="wrapper">
        <article>
            <div class="bubble">
                <img src="../assets/products/water-icons-04_wave.svg" alt="Stage 4 Wave Logo">
                <span>Tender & Buyback</span>
                <label>Wave</label>
            </div>
            <header>
                <h2>Smart contract tender to buyback tokens from the market.</h2>
            </header>
            <p>Buybacks are an essential part of a tokenized real estate property's lifecycle. Buying tokens back from investors can be designed in various ways and is mainly required when the property is being sold to a 3rd party buyer.
            </p>
            <ul list-style="checked">
                <li>Market your buyback to token holders</li>
                <li>Pre-negotiate your offer to investors</li>
                <li>Manage the buyback process</li>
            </ul>
            <a button rel="noopener noreferrer" href="https://blocksquare.typeform.com/to/BsDXI1">Get in touch</a>

        </article>
        <article class="card separated">
            <div class="stage">White-label platform</div>
            <div class="header">
                <div class="icon">
                    <img src="../assets/products/water-icons-04_wave.svg" alt="Stage 4 Wave Logo">
                </div>
                <h3>Wave</h3>
            </div>
            <div class="features">
                <span class="title">Tender & Buyback</span>
                <p>Organize a repurchase for your asset tokens.</p>
            </div>
            <div class="price">
                <button data-variant="outline" routerLink="/pricing" fragment="stages">See pricing</button>
            </div>
        </article>
    </div>
</section>
