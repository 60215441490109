import {Component} from '@angular/core';

@Component({
    selector: 'app-learn-tokenization',
    templateUrl: './tokenization.component.html',
    styleUrls: ['../learn.component.scss', '../../app.component.scss']
})
export class TokenizationComponent {

    constructor() {
    }
}
