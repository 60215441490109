import {Component, OnDestroy, OnInit} from '@angular/core';

@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss', '../app.component.scss']
})
export class ProductsComponent implements OnInit, OnDestroy {

    constructor() {
    }

    ngOnInit() {
        window.addEventListener('scroll', this.tokenizeBuildings, true);
    }

    ngOnDestroy() {
        window.removeEventListener('scroll', this.tokenizeBuildings, true);
    }

    tokenizeBuildings = (event: any): void => {
        const buildings = document.getElementsByTagName('table');
        let tokenAttribute = 'token';


        for (let b = 0; b < buildings.length; b++) {
            let buildingWindows = buildings[b].getElementsByTagName('td');
            for (let w = 0; w < buildingWindows.length; w++) {

                let buildingWindow = buildingWindows[w];

                if (buildingWindow.getAttribute(tokenAttribute) !== "") {
                    buildingWindow.setAttribute(tokenAttribute, '');
                    return;
                }
            }
        }

        window.removeEventListener('scroll', this.tokenizeBuildings, true);
    }
}
