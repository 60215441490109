import {Component, OnDestroy, OnInit} from '@angular/core';
import {Animation} from "../../../animations/Animation";
import {AppComponent} from "../../app.component";

@Component({
    selector: 'app-white-label-platform',
    templateUrl: './white-label-platform.component.html',
    styleUrls: ['../products.component.scss', '../../app.component.scss']
})
export class WhiteLabelPlatformComponent implements OnInit, OnDestroy {

  constructor() { }

    ngOnInit(): void {
        Animation.explodedView(!AppComponent.isMobile(), ".exploded");
        Animation.floating(!AppComponent.isMobile(), "#intro .scheme .bubble", "#intro .scheme");
    }

    ngOnDestroy(): void {
        Animation.killAllAnimations();
    }

    onImgLoad = (): void => Animation.refreshScrollTrigger();
}
