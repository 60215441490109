<section id="intro" background="5" data-layout="no-overflow">
    <div class="wrapper" data-layout="horizontal">
        <article>
            <header>
                <h1>A tokenization system, designed for real growth.</h1>
            </header>
            <p>Built to power 100s of investment portals across the globe, our real estate tokenization protocol enables entrepreneurs to digitise real estate assets at a fraction of the cost, while our white-label platform offers the quickest way to launch an online marketplace.</p>
            <a button rel="noopener noreferrer" href="https://blocksquare.typeform.com/to/BsDXI1">Contact sales</a>
        </article>
        <article class="scheme root">
            <div class="bubble">
                <img src="../assets/products/water-icons-01_spring.svg" alt="Stage 1 Spring Logo">
            </div>
            <div class="bubble">
                <img src="../assets/products/water-icons-02_delta.svg" alt="Stage 2 Delta Logo">
            </div>
            <div class="bubble">
                <img src="../assets/products/water-icons-05_ocean.svg" alt="Stage 5 Ocean Logo">
            </div>
            <div class="bubble">
                <img src="../assets/products/water-icons-03_tide.svg" alt="Stage 3 Tide Logo">
            </div>
            <div class="bubble">
                <img src="../assets/products/water-icons-04_wave.svg" alt="Stage 4 Wave Logo">
            </div>
        </article>
    </div>
</section>

<section id="all-stages">
    <div class="wrapper" data-layout="row-wrap">
        <article class="card separated">
            <div class="details">
                <div class="header">
                    <div class="stage">[stage 1]</div>
                </div>
                <div class="header">
                    <h3>Spring</h3>
                </div>
                <span class="title">Structure & Digitize</span>
                <div class="price">
                    <button data-variant="outline" routerLink="/products/tokenization-protocol" fragment="stage1">Learn more</button>
                </div>
            </div>
            <div class="features">
                <div class="icon">
                    <img src="../assets/products/water-icons-01_spring.svg" alt="Stage 1 Spring Logo">
                </div>
            </div>
        </article>
        <article class="card separated">
            <div class="details">
                <div class="header">
                    <div class="stage">[stage 2]</div>
                </div>
                <div class="header">
                    <h3>Delta</h3>
                </div>
                <span class="title">Offer & Distribute</span>
                <div class="price">
                    <button data-variant="outline" routerLink="/products/stage2">Learn more</button>
                </div>
            </div>
            <div class="features">
                <div class="icon">
                    <img src="../assets/products/water-icons-02_delta.svg" alt="Stage 2 Delta Logo">
                </div>
            </div>
        </article>
        <article class="card separated">
            <div class="details">
                <div class="header">
                    <div class="stage">[stage 3]</div>
                </div>
                <div class="header">
                    <h3>Tide</h3>
                </div>
                <span class="title">Monitor & Report</span>
                <div class="price">
                    <button data-variant="outline" routerLink="/products/white-label-platform" fragment="stage3">Learn more</button>
                </div>
            </div>
            <div class="features">
                <div class="icon">
                    <img src="../assets/products/water-icons-03_tide.svg" alt="Stage 3 Tide Logo">
                </div>
            </div>
        </article>
        <article class="card separated">
            <div class="details">
                <div class="header">
                    <div class="stage">[stage 4]</div>
                </div>
                <div class="header">
                    <h3>Wave</h3>
                </div>
                <span class="title">Tender & Buyback</span>
                <div class="price">
                    <button data-variant="outline" routerLink="/products/white-label-platform" fragment="stage4">Learn more</button>
                </div>
            </div>
            <div class="features">
                <div class="icon">
                    <img src="../assets/products/water-icons-04_wave.svg" alt="Stage 4 Wave Logo">
                </div>
            </div>
        </article>
    </div>
</section>


<section id="movie" theme="dark no-bg" class="has-slope">
    <div class="slope" background></div>
    <div class="wrapper">
        <article>
            <video controls height="100%" width="100%">
                <source src="../assets/products/tokenization-protocol/stage1/movie.mp4" type="video/mp4">
                Your browser does not support the video tag.
            </video>
        </article>

        <article align="left">
            <div class="heading">
                <label>Real estate waits for your call</label>
                <h2>The technology backbone for a new digital market.</h2>
            </div>
            <p>For ambitious companies around the world, Blocksquare makes real estate investments as simple, borderless, and programmable as the rest of the internet.</p>
            <a button rel="noopener noreferrer" href="https://blocksquare.typeform.com/to/BsDXI1">Get in touch</a>
        </article>
    </div>
</section>

<section id="all-products-features" data-layout="vertical">
    <div class="wrapper">
        <header>
            <label>A complete system</label>
            <h2>The power of tokenization in your hands.</h2>
        </header>

        <div class="wrapper" data-layout="grid icon-list-h">
            <div>
                <img src="../../assets/learn/propositions/icon-01.svg" alt="Proposition icon 1"/>
                <h4>Designed exclusively for real estate assets.</h4>
            </div>
            <div>
                <img src="../../assets/learn/propositions/icon-09.svg" alt="Proposition icon 9"/>
                <h4>Offer digital real estate investments globally.</h4>
            </div>
            <div>
                <img src="../../assets/learn/propositions/icon-02.svg" alt="Proposition icon 2"/>
                <h4>Technology for future-oriented businesses.</h4>
            </div>
            <div>
                <img src="../../assets/learn/propositions/icon-10.svg" alt="Proposition icon 10"/>
                <h4>Transparent, real-time performance and monitoring.</h4>
            </div>
            <div>
                <img src="../../assets/learn/propositions/icon-06.svg" alt="Proposition icon 6"/>
                <h4>Generate smart contracts linked to individual properties.</h4>
            </div>
            <div>
                <img src="../../assets/learn/propositions/icon-04.svg" alt="Proposition icon 4"/>
                <h4>Distribute revenues to 1000s of token holders.</h4>
            </div>
            <div>
                <img src="../../assets/learn/propositions/icon-03.svg" alt="Proposition icon 3"/>
                <h4>Issue up to 100,000 tokens for each single property.</h4>
            </div>
            <div>
                <img src="../../assets/learn/propositions/icon-07.svg" alt="Proposition icon 7"/>
                <h4>Tokenize real estate assets partially or in full.</h4>
            </div>
            <div>
                <img src="../../assets/learn/propositions/icon-12.svg" alt="Proposition icon 12"/>
                <h4>24/7 peer-to-peer trading for all your tokenized assets.</h4>
            </div>
            <div>
                <img src="../../assets/learn/propositions/icon-11.svg" alt="Proposition icon 11"/>
                <h4>Reduce transaction costs of expensive middlemen</h4>
            </div>
            <div>
                <img src="../../assets/learn/propositions/icon-05.svg" alt="Proposition icon 5"/>
                <h4>Built on Ethereum — the largest blockchain infrastructure.</h4>
            </div>
            <div>
                <img src="../../assets/learn/propositions/icon-08.svg" alt="Proposition icon 8"/>
                <h4>GUIs for non-coders, APIs for tech-savvy teams.</h4>
            </div>
        </div>
    </div>
</section>
