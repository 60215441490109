import {Component} from '@angular/core';

@Component({
    selector: 'app-learn-defi',
    templateUrl: './defi.component.html',
    styleUrls: ['../learn.component.scss', '../../app.component.scss']
})
export class DefiComponent {

    constructor() {
    }
}
