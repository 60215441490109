import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
    private fragment: string;
    private navLinks: HTMLElement;
    private static minimizedAttribute = 'minimized-mobile';

    constructor(router: Router, private route: ActivatedRoute) {

        router.events.subscribe(s => {
            AppComponent.setElementHidden(this.navLinks, AppComponent.isMobile);

            if (s instanceof NavigationEnd) {
                const tree = router.parseUrl(router.url);
                if (tree.fragment) {
                    const element = document.querySelector("#" + tree.fragment);
                    if (element) {
                        element.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                            inline: 'center'
                        });
                    }
                } else {
                    // TODO matejk: https://stackoverflow.com/questions/51713087/set-url-fragment-on-browser-back-navigation-in-angular
                    document.documentElement.scrollTop = 0;
                }
            }
        });
    }

    ngOnInit() {
        this.route.fragment.subscribe(fragment => {
            this.fragment = fragment;
        });

        this.navLinks = document.querySelector('nav .links');

        AppComponent.setElementHidden(this.navLinks, AppComponent.isMobile);
        this.hideNavbarOnMobileScroll();
    }

    private hideNavbarOnMobileScroll() {
        let lastScrollTop = 0;
        let navElement = document.getElementsByTagName("nav")[0];
        window.onscroll = () => {
            if (AppComponent.isMobile) {
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop
                AppComponent.setElementHidden(navElement, scrollTop > lastScrollTop)
                lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
            }
        }
    }

    private static setElementHidden(element, hidden) {
        if (!element) {
            console.debug("setElementHidden: Can't hide element", element);
            return;
        }

        if (hidden) {
            element.setAttribute(this.minimizedAttribute, '');
        } else {
            element.removeAttribute(this.minimizedAttribute);
        }
    }

    private static toggleElementHidden(element) {
        if (!element) {
            console.debug("toggleElementHidden: Can't toggle element", element);
            return;
        }
        AppComponent.setElementHidden(element, element.getAttribute(this.minimizedAttribute) !== '');
    }

    static isMobile() {
        return window.innerWidth < 600;
    }


    toggleLinks() {
        AppComponent.toggleElementHidden(this.navLinks);
    }

    isFragmentActive(fragment: string): boolean {
        return location.href.indexOf(fragment) !== -1;
    }

    ngAfterViewInit(): void {
        try {
            document.querySelector('#' + this.fragment).scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
            });
        } catch (e) {
        }
    }
}