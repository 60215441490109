import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tokenization-protocol',
  templateUrl: './tokenization-protocol.component.html',
  styleUrls: ['../products.component.scss', '../../app.component.scss']
})
export class TokenizationProtocolComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
