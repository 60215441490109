import {Component, OnInit} from '@angular/core';
import {Animation} from "../../animations/Animation";
import {AppComponent} from "../app.component";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss', '../app.component.scss']
})
export class HomeComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
        Animation.satellites('.scheme');
        Animation.globe('.scheme #worldmap svg');
    }

    ngOnDestroy(): void {
        Animation.killAllAnimations();
    }

}
