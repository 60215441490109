<section id="intro" class="subpage" background="5">
    <div class="wrapper" data-layout="horizontal">
        <article>
            <header>
                <h1>DeFi</h1>
            </header>
        </article>
    </div>
</section>

<section id="navigation">
    <div class="wrapper">
        <article class="card" routerLink="/learn/regulation">
            <img src="../../../assets/learn/intro/learn-icons-regulation.svg" alt="">
            <button data-variant="arrow" class="action">Regulation</button>
            <div>Europe pushing towards a digital financial future.</div>
        </article>

        <article class="card" routerLink="/learn/company">
            <img src="../../../assets/learn/intro/learn-icons-company.svg" alt="">
            <button data-variant="arrow" class="action">Company</button>
            <div>A reliable team of experts.</div>
        </article>
    </div>
</section>
