<section id="intro" class="intro-background subpage" data-layout="no-overflow">
    <div class="wrapper" data-layout="horizontal">
        <article>
            <header>
                <label>Blockchain technology</label>
                <h1>Decentralized trust layer for the online economy.</h1>
            </header>
            <p>The tokenization of assets and the creation of new infrastructure to trade and manage digital tokens using blockchain technology is set to be as transformative to the world economy as the advent of the internet.</p>
            <button data-variant="outline" routerLink="/learn/blockchain" fragment="blockchain">Learn more</button>
        </article>

        <article class="scheme" data-hidden="mobile">
            <img src="assets/learn/learn-blockchain.svg" alt="Increase liquidity">
        </article>
    </div>
</section>

<section id="smart-contracts" background="1">
    <div class="wrapper" data-layout="top">
        <header>
            <label>Smart contracts</label>
            <h2>What is a smart contract?</h2>
        </header>
        <article>
            <p>In short, a smart contract is a relatively simple "program" that lives on a blockchain.</p>
            <p>To better understand the concept consider the purchase of a beverage can from a vending machine. The buyer deposits change and then inputs a reference number for the selection. The number is mapped against that particular slot and activates a lever in the machine to push out the product. No cashier or clerk was required for the transaction.</p>
            <p>A smart contract is similar to a vending machine in that it eliminates the need for an intermediary. In the case above, the vending machine is replacing a direct seller and allowing the consumer to make a purchase without a middleman.</p>
            <p>Smart contracts are self-executing programs containing the terms and conditions of an agreement among peers. The most widely used smart contract platform is Ethereum.</p>
            </article>
        <article>
            <p>The terms and conditions of the agreement are written into code. The smart contract executes on the Ethereum blockchain's decentralized platform. The agreements facilitate the exchange of money, shares, property, or any asset. There are two widely-used programming languages for writing Ethereum smart contracts:</p>
            <ul list-style="checked">
                <li>Solidity, and</li>
                <li>Serpent.</li>
            </ul>
            <p>Solidity is a high-level programming language used for implementing smart contracts on the Ethereum blockchain platform. It enables blockchain developers to check the program at runtime rather than compile-time.</p>
        </article>
    </div>
</section>

<section id="blockchain" theme="dark no-bg" class="has-slope">
    <div class="slope" background></div>
    <div class="wrapper" data-layout="horizontal">
        <article>
            <header>
                <label>Blockchain technology</label>
                <h2>The technology backbone of the internet of value.</h2>
            </header>
            <p>Blockchain is one of the most advanced networking technologies ever invented. From the complexity and security of the hashing algorithm to the distributed nature of its sharing and processing, blockchains are truly innovative.</p>
            <p>A report published by the World Economic Forum predicts that, by as early as 2027, around 10% of global GDP could be stored on blockchain technology.</p>
            <a button rel="noopener noreferrer" href="https://blocksquare.typeform.com/to/BsDXI1">Contact us</a>
        </article>
    </div>
</section>

<section id="all-products-features" data-layout="vertical">
    <div class="wrapper">
        <header>
            <label>Using blockchain</label>
            <h2>Benefits and advantages of blockchain technology.</h2>
        </header>

        <article>
            <p>Blockchain is a list of records called blocks that store information in chronological order. Each record is 'paid' for and thus considered a transaction. The use of cryptography ensures transactions cannot be altered after completion.</p>
            <p>Information on a Blockchain network is not controlled by a centralized authority, but rather maintained by numerous participants who hold the democratic authority to approve any and all transaction. Therefore, a typical Blockchain network is public.</p>
        </article>

        <div class="wrapper" data-layout="grid icon-list-h">
            <div>
                <img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>
                <h4>Increased transaction speed for settling transfers.</h4>
            </div>
            <div>
                <img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>
                <h4>Better security with use of cryptography.</h4>
            </div>
            <div>
                <img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>
                <h4>Lower costs on maintenance and intermediaries.</h4>
            </div>
            <div>
                <img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>
                <h4>Improved data quality through use of smart contracts.</h4>
            </div>
            <div>
                <img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>
                <h4>Better error solving through public audit trails.</h4>
            </div>
            <div>
                <img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>
                <h4>Accountability of accounts and asset transfers.</h4>
            </div>
            <div>
                <img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>
                <h4>Protection of data and user control.</h4>
            </div>
            <div>
                <img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>
                <h4>Reduced fraud and hacker resistance.</h4>
            </div>
            <div>
                <img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>
                <h4>Higher transparency and transaction traceability.</h4>
            </div>
            <div>
                <img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>
                <h4>Simplification of money and value transfers.</h4>
            </div>
            <div>
                <img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>
                <h4>Emergence of new financial products and services.</h4>
            </div>
            <div>
                <img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>
                <h4>Automated recording and authentication of data.</h4>
            </div>
        </div>
    </div>
</section>

<section id="navigation">
    <div class="wrapper">
        <article class="card" routerLink="/learn/tokenization">
            <img src="../assets/learn/propositions/icon-03.svg" alt="">
            <button data-variant="arrow" class="action">Tokenization</button>
            <div>100s of real estate markets suffer from illiquidity.</div>
        </article>

<!--        <article class="card" routerLink="/learn/regulation">-->
<!--            <img src="../../../assets/learn/intro/learn-icons-regulation.svg" alt="">-->
<!--            <label>Regulation</label>-->
<!--            <div>Europe pushing towards a digital financial future.</div>-->
<!--        </article>-->
        <article class="card" routerLink="/learn/company">
            <img src="../../../assets/learn/intro/learn-icons-company.svg" alt="">
            <button data-variant="arrow" class="action">Company</button>
            <div>A reliable team of experts.</div>
        </article>
    </div>
</section>
