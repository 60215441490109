export class NewsAggregator {
    private mediumApi: { isLoading: boolean; dataSource: null };
    private localFile: { isLoading: boolean; dataSource: null };

    constructor() {
        this.mediumApi = {
            isLoading: true,
            dataSource: null,
        };

        this.localFile = {
            isLoading: true,
            dataSource: null,
        };
    }

    static getFeaturedNewsJson = (newsSourceJson, switchToBigNews: boolean = null) => {
        return newsSourceJson.default
            .filter(post => switchToBigNews != null ? post.big == switchToBigNews : post)
            .sort(({date: postDate}, {date: post1Date}) => {
                return (new Date(post1Date)).valueOf() - (new Date(postDate)).valueOf();
            })
    };

    static getStoriesJson = (newsSourceApiUrl, filterByHashtag) => {
        return null;
    };

    static getAllNews = () => {

    };
}
