import { Component, OnInit } from '@angular/core';
import {Animation} from "src/animations/Animation";
import {AppComponent} from "src/app/app.component";

@Component({
  selector: 'app-tokenization-protocol',
  templateUrl: './defi-bridge.component.html',
  styleUrls: ['../products.component.scss', '../../app.component.scss']
})
export class DefiBridgeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    Animation.globe('.scheme #worldmap svg');
    Animation.floating(!AppComponent.isMobile(), "#intro .scheme .bubble", "#intro .scheme");
  }

  ngOnDestroy(): void {
    Animation.killAllAnimations();
  }

}
