<section id="intro" background="5">
    <div class="wrapper" data-layout="horizontal">
        <article>
            <header>
                <div class="bubble">
                    <img src="../assets/products/water-icons-05_ocean.svg" alt="Stage 5 Ocean Logo">
                    <span>Liquidity protocol</span>
                    <label>Oceanpoint</label>
                </div>
                <h1><label>Defi</label> for real estate assets</h1>
            </header>
            <p>Oceanpoint is a liquidity engine that opens access to borderless financing for property owners anywhere by aggregating assets under tokenization with a USD correlating stablecoin.</p>
            <a button rel="noopener noreferrer" href="https://oceanpoint.fi/">Learn more</a>
        </article>

        <article class="scheme defi">
            <section id="turnglobe">
                <div id="worldmap">
                    <svg></svg>
                </div>
                <div class="tiles">
                    <div class="bubble">
                        <img src="../assets/home/intro/Asset37.png" alt="A part of the Blocksquare platform">
                    </div>
                    <div class="bubble">
                        <img src="../assets/home/intro/Asset38.png" alt="A part of the Blocksquare platform">
                    </div>
                    <div class="bubble">
                        <img src="../assets/home/intro/Asset36.png" alt="A part of the Blocksquare platform">
                    </div>
                    <div class="bubble">
                        <img src="../assets/home/intro/Ethereum-icon-purple.svg" alt="Ethereum as part of the Blocksquare platform">
                    </div>
                    <div class="bubble">
                        <img src="../assets/home/intro/Asset35.png" alt="A part of the Blocksquare platform">
                    </div>
                    <div class="bubble">
                        <img src="../assets/home/intro/Dai-logo.png" alt="DAI as part of the Blocksquare platform">
                    </div>
                    <div class="bubble">
                        <img src="../assets/home/intro/Asset33.png" alt="A part of the Blocksquare platform">
                    </div>
                    <div class="bubble">
                        <img src="../assets/home/intro/Asset34.png" alt="A part of the Blocksquare platform">
                    </div>
                    <div class="bubble">
                        <img src="../assets/home/intro/blocksquare.png" alt="Blocksquare">
                    </div>
                    <div class="bubble">
                        <span>Staking is live</span>
                    </div>
                </div>
            </section>
        </article>
    </div>
</section>

<section id="fees" theme="dark no-bg" class="has-slope">
    <div class="slope" background></div>
    <div class="wrapper" data-layout="horizontal split">
        <article>
            <header>
                <label>Stake real estate assets. Earn crypto.</label>
                <h2>Add a new revenue stream with your existing portfolio.</h2>
            </header>
                <p>Oceanpoint is a set of smart contracts on Ethereum that form an open-end DAO designed to potentially own an unlimited pool of real estate assets, where anyone can participate without legal restrictions to earn by contributing to a decentralized protocol backed by the real estate economy.</p>
                <p>With staking available over various pool types, Oceanpoint rests on three main pillars:</p>
                <ul list-style="checked">
                    <li>Defi products</li>
                    <li>Real estate tokenization</li>
                    <li>Community governance</li>
                </ul>
                <a button rel="noopener noreferrer" href="https://oceanpoint.fi" target="_blank">Learn more</a>
        </article>
        <article class="scheme">
            <img src="assets/products/oceanpoint/Oceanpoint-token_flow.png" alt="Products scheme">
        </article>
    </div>
</section>

<section id="oceanpoint" background="3">
    <div class="wrapper">
        <article>
            <div class="bubble">
                <img src="../assets/products/water-icons-05_ocean.svg" alt="Stage 5 Oceanpoint Logo">
                <span>Liquidity protocol</span>
                <label>Oceanpoint</label>
            </div>
            <header>
                <h2>Connecting Decentralized Finance to real estate assets.</h2>
            </header>
            <p>Developed and powered by Blocksquare, Oceanpoint is a set of smart contracts on Ethereum that form an open-end DAO designed to potentially own an unlimited pool of real estate assets, where anyone can participate without legal restrictions to earn by contributing to a decentralized protocol backed by the real estate economy.</p>
            <label></label>
            <ul list-style="checked">
                <li><strong>$500k</strong>&nbsp;staked in the first 24 hours</li>
                <li><strong>$1M</strong>&nbsp;Market Cap reached in the first 24 hours</li>
                <li><strong>38%</strong>&nbsp;of BST staked in the first week</li>
            </ul>
            <a button rel="noopener noreferrer" href="https://blocksquare.typeform.com/to/BsDXI1">Get in touch</a>
        </article>
        <article class="card separated">
            <div class="stage">Liquidity protocol</div>
            <div class="header">
                <div class="icon">
                    <img src="../assets/products/water-icons-05_ocean.svg" alt="Stage 5 Oceanpoint Logo">
                </div>
                <h3>Oceanpoint</h3>
            </div>
            <div class="features">
                <span class="title">Defi for real estate</span>
                <p>Borderless financing for property owners anywhere.</p>
            </div>
            <div class="price">
                <a button rel="noopener noreferrer" href="https://app.oceanpoint.fi">Start staking</a>
            </div>
        </article>
    </div>
</section>
