import {Component} from '@angular/core';

@Component({
    selector: 'app-learn-regulation',
    templateUrl: './regulation.component.html',
    styleUrls: ['../learn.component.scss', '../../app.component.scss']
})
export class RegulationComponent {

    constructor() {
    }
}
