import {Component, OnDestroy, OnInit} from '@angular/core';
import {Animation} from "../../animations/Animation";

@Component({
    selector: 'app-pricing',
    templateUrl: './pricing.component.html',
    styleUrls: ['./pricing.component.scss', '../app.component.scss']
})
export class PricingComponent implements OnInit, OnDestroy {
    constructor() {
    }

    ngOnInit(): void {
        let slidingWindow;
        setDesktopAnimation();

        function setDesktopAnimation() {
            Animation.killAllAnimations();
            if (window.innerWidth > 1376) {
                slidingWindow = Animation.slidingWindow(!slidingWindow);
            }
        }

        window.onresize = () => setDesktopAnimation();
    }

    ngOnDestroy(): void {
        Animation.killAllAnimations();
    }
}
