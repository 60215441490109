import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-pricing',
    templateUrl: './token.component.html',
    styleUrls: ['./token.component.scss', '../app.component.scss']
})
export class TokenComponent implements OnInit {
    constructor() {
    }

    ngOnInit(): void {
        this.loadExternalScript("https://files.coinmarketcap.com/static/widget/currency.js")
    }

    public loadExternalScript(url: string) {
        const body = <HTMLDivElement> document.body;
        const script = document.createElement('script');
        script.innerHTML = '';
        script.src = url;
        script.async = true;
        script.defer = true;
        body.appendChild(script);
    }

}
