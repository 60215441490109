<nav>
    <div class="wrapper">
        <a class="logo img-fixed" routerLink="/">
            <img src="../assets/app/logo/logo.svg" alt="Blocksquare logo">
        </a>

        <ul class="links">
            <li menu>
                <span routerLink="/products" routerLinkActive="active">Products</span>
                <div class="submenu">
                    <strong class="uppercase">Tokenization protocol</strong>
                    <div class="content">
                        <a class="item" routerLink="/products/tokenization-protocol" fragment="spring" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <div class="thumbnail">
                                <img src="../assets/products/water-icons-01_spring.svg" alt="Stage 1 Spring Logo">
                            </div>
                            <div class="details">
                                <div class="title">Spring</div>
                                <small>Structure & Digitize</small>
                            </div>
                        </a>
                    </div>
                    <strong class="uppercase">White-label platform</strong>
                    <div class="content">
                        <a class="item" routerLink="/products/white-label-platform" fragment="delta" [class.active]="isFragmentActive('delta')">
                            <div class="thumbnail">
                                <img src="../assets/products/water-icons-02_delta.svg" alt="Stage 2 Delta Logo">
                            </div>
                            <div class="details">
                                <div class="title">Delta</div>
                                <small>Offer & Distribute</small>
                            </div>
                        </a>
                        <a class="item" routerLink="/products/white-label-platform" fragment="tide" [class.active]="isFragmentActive('tide')">
                            <div class="thumbnail">
                                <img src="../assets/products/water-icons-03_tide.svg" alt="Stage 3 Tide Logo">
                            </div>
                            <div class="details">
                                <div class="title">Tide</div>
                                <small>Monitor & Report</small>
                            </div>
                        </a>
                        <a class="item" routerLink="/products/white-label-platform" fragment="wave" [class.active]="isFragmentActive('wave')">
                            <div class="thumbnail">
                                <img src="../assets/products/water-icons-04_wave.svg" alt="Stage 4 Wave Logo">
                            </div>
                            <div class="details">
                                <div class="title">Wave</div>
                                <small>Tender & Buyback</small>
                            </div>
                        </a>
                    </div>
                    <strong class="uppercase">DeFi bridge</strong>
                    <div class="content">
                        <a class="item" routerLink="/products/defi-bridge" routerLinkActive="active">
                            <div class="thumbnail">
                                <img src="../assets/products/water-icons-05_ocean.svg" alt="Stage 5 Ocean Logo">
                            </div>
                            <div class="details">
                                <div class="title">Oceanpoint</div>
                                <small>Liquidity protocol</small>
                            </div>
                        </a>
                    </div>
                    <hr>
                </div>
            </li>

            <li>
                <span routerLink="/pricing" routerLinkActive="active">Pricing</span>
            </li>

            <li>
                <span routerLink="/token" routerLinkActive="active">Token</span>
            </li>

            <li menu>
                <span routerLink="/learn" routerLinkActive="active">Learn</span>
                <div class="submenu">
                    <strong class="uppercase">Technology</strong>
                    <div class="content">
                        <a class="item" routerLink="/learn/tokenization" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <div class="thumbnail">
                                <img src="../assets/learn/propositions/icon-03.svg" alt="">
                            </div>
                            <div class="details">
                                <div class="title">Tokenization</div>
                                <small>Transforming real estate into digital assets</small>
                            </div>
                        </a>
                        <a class="item" routerLink="/learn/blockchain" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <div class="thumbnail">
                                <img src="../assets/learn/intro/learn-icons-blockchain.svg" alt="">
                            </div>
                            <div class="details">
                                <div class="title">Blockchain</div>
                                <small>Technology backbone for a new market</small>
                            </div>
                        </a>
                    </div>
                    <strong class="uppercase">Market</strong>
                    <div class="content">
                        <!--                        <a class="item" routerLink="/learn/regulation" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">-->
                        <a class="item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <div class="thumbnail">
                                <img src="../assets/learn/intro/learn-icons-regulation.svg" alt="">
                            </div>
                            <div class="details">
                                <div class="title">Regulation</div>
                                <small>Available soon</small>
                            </div>
                        </a>
                        <!--                        <a class="item" routerLink="/learn/defi" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">-->
                        <a class="item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <div class="thumbnail">
                                <img src="../assets/learn/intro/learn-icons-defi.svg" alt="">
                            </div>
                            <div class="details">
                                <div class="title">DeFi</div>
                                <small>Available soon</small>
                            </div>
                        </a>
                    </div>
                    <strong class="uppercase">About us</strong>
                    <div class="content">
                        <a class="item" routerLink="/learn/company" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <div class="thumbnail">
                                <img src="../assets/learn/intro/learn-icons-company.svg" alt="">
                            </div>
                            <div class="details">
                                <div class="title">Company</div>
                                <small>Leaders in real estate tokenization solutions</small>
                            </div>
                        </a>
                        <a class="item" routerLink="/learn/newsroom" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <div class="thumbnail">
                                <img src="../assets/learn/intro/learn-icons-newsroom.svg" alt="">
                            </div>
                            <div class="details">
                                <div class="title">Newsroom</div>
                                <small>Announcements, articles and more</small>
                            </div>
                        </a>
                    </div>

                    <hr>
                </div>
            </li>

            <li>
                <span>
                    <a button rel="noopener noreferrer" data-variant="external" href="https://docs.blocksquare.io/">Docs</a>
                </span>
            </li>

            <li class="scrim"></li>
        </ul>

        <div class="actions">
            <a button rel="noopener noreferrer" href="https://blocksquare.typeform.com/to/BsDXI1">Send Inquiry</a>
            <button id="toggleLinks" class="action" data-variant="outline" (click)="toggleLinks()">
                <span></span>
                <span></span>
                <span></span>
            </button>
        </div>
    </div>
</nav>

<router-outlet></router-outlet>

<footer>
    <div class="background"></div>
    <section data-layout="no-spacing" class="text">
        <div class="wrapper">
            <article>
                <div>
                    <div class="locations">
                        <div>
                            <label>Headquarters</label>
                            <p>
                                Križevniška 8<br>
                                1000 Ljubljana<br>
                                Slovenia EU
                            </p>
                        </div>

                        <div>
                            <label>Branch office</label>
                            <p>
                                Dammstrasse 16<br>
                                6300 Zug<br>
                                Switzerland<br>
                            </p>
                        </div>

                        <a rel="noopener noreferrer" href="https://fibree.org" class="badge">
                            <img src="assets/app/badge/Fibree-Batch-01.svg" alt="fibree-badge" />
                        </a>
                    </div>

                    <div class="social">
                        <a rel="noopener noreferrer" href="mailto:future@blocksquare.io" class="icon-mail-alt"></a>
                        <a rel="noopener noreferrer" href="http://go.blocksquare.io/facebook" class="icon-facebook"></a>
                        <a rel="noopener noreferrer" href="http://go.blocksquare.io/twitter" class="icon-twitter"></a>
                        <a rel="noopener noreferrer" href="http://go.blocksquare.io/telegram" class="icon-telegram"></a>
                        <a rel="noopener noreferrer" href="http://go.blocksquare.io/medium" class="icon-medium"></a>
                        <a rel="noopener noreferrer" href="http://go.blocksquare.io/reddit" class="icon-reddit-alien"></a>
                        <a rel="noopener noreferrer" href="http://go.blocksquare.io/linkedin" class="icon-linkedin"></a>
                    </div>
                </div>
                <div>Blocksquare is made with 🤍 in 🇸🇮🇪🇺 and supported by our friends in 🇨🇭</div>
            </article>

            <article class="links map" data-layout="horizontal">

                <div>
                    <label routerLink="/products">Products</label>
                    <ul>
                        <li>
                            <span routerLink="/products/tokenization-protocol">Tokenization protocol</span>
                        </li>
                        <li>
                            <span routerLink="/products/white-label-platform">White-label platform</span>
                        </li>
                    </ul>
                    <label routerLink="/pricing">Pricing</label>
                </div>

                <div>
                    <label routerLink="/learn">Learn</label>
                    <ul>
                        <li>
                            <span routerLink="/learn/tokenization">Tokenization</span>
                        </li>
                        <li>
                            <span routerLink="/learn/blockchain">Blockchain</span>
                        </li>
                        <!--                        <li>-->
                        <!--                            <span routerLink="/learn/regulation">Regulation</span>-->
                        <!--                        </li>-->
                        <!--                        <li>-->
                        <!--                            <span routerLink="/learn/defi">DeFi</span>-->
                        <!--                        </li>-->
                        <li>
                            <span routerLink="/learn/company">Company</span>
                        </li>
                        <li>
                            <span routerLink="/learn/newsroom">Newsroom</span>
                        </li>
                    </ul>
                </div>

            </article>
        </div>
    </section>
</footer>
