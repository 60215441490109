import {Component} from '@angular/core';

@Component({
    selector: 'app-learn-blockchain',
    templateUrl: './blockchain.component.html',
    styleUrls: ['../learn.component.scss', '../../app.component.scss']
})
export class BlockchainComponent {

    constructor() {
    }
}
