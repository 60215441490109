<section id="intro" background="5">
    <div class="wrapper" data-layout="horizontal">
        <article>
            <header>
                <div class="bubble">
                    <img src="../assets/products/water-icons-01_spring.svg" alt="Stage 1 Spring Logo">
                    <span>Tokenization Protocol</span>
                    <label>Spring</label>
                </div>
                <h1>Scalable tokenization protocol for real estate assets.</h1>
            </header>
        </article>

        <article class="tiles">
            <div class="card">
                <img src="../assets/products/tokenization-protocol/stage1/IPFS_logo.png" alt="IPFS Logo as part of the Stage1 intro graphics">
            </div>
            <div class="card">
                <img src="../assets/app/logo/logo.svg" alt="Ethereum Logo as part of the Stage1 intro graphics">
            </div>
            <div class="card">
                <img src="../assets/products/tokenization-protocol/stage1/ethereum-eth-logo-full-horizontal.svg" alt="Ethereum Logo as part of the Stage1 intro graphics">
            </div>
        </article>
    </div>
</section>

<section id="tokenization-standard">
    <div class="wrapper">
        <article class="scheme">
            <img src="../../assets/home/digitize/building-to-tokens-3d.svg" alt="Digitize">
        </article>
        <article>
            <header>
                <label>PropToken standard</label>
                <h2>Tokenize any real estate property. From long-term investments to short-term deals.</h2>
            </header>
            <p>Blocksquare’s real estate tokenization Protocol provides our Client a standardized method of digitizing the value of a single real estate property, either partially or in full.</p>
            <ul list-style="checked">
                <li>100,000 tokens for each property</li>
                <li>1,000 tokens for each 1% of the capital stack</li>
                <li>On-chain property valuation</li>
                <li>Legally binding and enforceable tokens</li>
                <li>Revenue-based asset tokenization</li>
            </ul>
            <!--TODO denis: kam naj kaže ta gumb?-->
            <button data-variant="outline">Learn more</button>
        </article>
    </div>
</section>

<section background="4"></section>

<section id="protocol-overview">
    <div class="wrapper" data-layout="top">
        <header>
            <label>Protocol overview</label>
            <h2>Transferring real world value to digital tokens.</h2>
        </header>
        <article>
            <p>The Protocol is the process of creating a 'PropToken' smart contract - a standard Ethereum ERC-20 smart contract with a defined maximum issuance of 100,000 digital tokens, extended with unique information identifying a specific real estate property, and additionally limiting transactional rules according to the issuer’s requirements (which users can transact with the tokens is defined within a separate 'DataHolder' smart contract).</p>
            <p>To transfer economic value deriving from the specified real estate property, a Public Corporate Resolution is created and signed by shareholders of the issuing legal entity. The signed resolution is made public through an upload to IPFS - a distributed file system that ensures the legal document has a fixed online address, while the file itself can not be tampered with after it has been uploaded and becomes in a way public. The location of the file is defined as a unique IPFS hash and recorded within the PropToken smart contract. The 'PropToken' now points towards the Corporate Resolution and vice-versa, so the legal effects of the corporate resolution work in conjunction with the tokens managed by the 'PropToken' smart contract.</p>
        </article>
        <article>
            <p>The corporate resolution is used to transfer any and all net revenues (revenues upon deduction of all expenses) that the issuing legal entity generates within the scope of its commercial and trading activity with the specified real estate property to the individual digital token holder of the PropToken smart contract, where 100,000 digital tokens represents 100% of any and all net revenues.</p>
            <p>The above method of tokenizing a real estate property or multiple properties forms the Protocol. The protocol combines readily available technologies and legal procedures:</p>
            <ul list-style="checked">
                <li>Public blockchain</li>
                <li>Smart contracts</li>
                <li>Public distributed file system</li>
                <li>Legally binding corporate resolution</li>
            </ul>
        </article>

    </div>
</section>

<section id="schedule-demo" data-layout="vertical">
    <div class="wrapper">
        <article align="center">
            <div class="diagram">
                <div class="right">
                    <div class="title">real estate smart contract</div>
                    <div class="image">
                        <span>ERC-20</span>
                        <img src="../assets/app/icon/document.svg" alt="Diagram Document Icon" style="background-color: var(--background-color)">
                    </div>
                </div>
                <div class="arrows">
                    <img src="../assets/app/icon/chain.svg" alt="Diagram Chain Icon"/>
                </div>
                <div class="left">
                    <div class="image">
                        <span>IPFS</span>
                        <img src="../assets/app/icon/document.svg" alt="Diagram Document Icon" style="background-color: var(--background-color)">
                    </div>
                    <div class="title">corporate resolution</div>
                </div>
            </div>
            <a button rel="noopener noreferrer" href="https://blocksquare.typeform.com/to/BsDXI1">Schedule demo</a>
        </article>
    </div>
</section>

<section id="fees" theme="dark no-bg" class="has-slope">
    <div class="slope" background></div>
    <div class="wrapper diagram" data-layout="horizontal split">
        <header>
            <label>Distributed fees</label>
            <h2>Network stakeholders get transaction fees.</h2>
            <p>At each transaction a fee of 1.5% tokens is deducted and credited to network stakeholders. Fees are always paid by the buyer and executed in property tokens, e.g. if a transaction involves 10,000 tokens, the buyer will receive 10,000 - 1.5% tokens = 9,850 tokens on their wallet even if tokens are transferred outside the platform. The fee distribution to beneficiaries is automated.</p>
            <ul list-style="checked">
                <li>0.5% goes to the platform operator</li>
                <li>0.5% goes to the issuing company</li>
                <li>0.5% goes to Blocksquare</li>
            </ul>
            <a button rel="noopener noreferrer" href="https://blocksquare.typeform.com/to/BsDXI1">Get in touch</a>
        </header>
        <section class="scheme">
            <img src="assets/home/roles/3-products-darkbg.svg" alt="Products scheme">
        </section>
    </div>
</section>

<section id="details">

    <div class="wrapper">
        <article>
            <header>
                <label>Structure and digitize</label>
                <h2>Convert any real estate property into 100,000 tokens.</h2>
            </header>
            <p>Tokenization of real estate assets through our protocol adds transparency and on-chain valuation data, so you can address investors with a fully digital investment product they can monitor and access online.
            </p>
            <ul list-style="checked">
                <li>Issue up to 100,000 tokens for each property</li>
                <li>Structure your real estate assets for online investors</li>
                <li>Pay per issuance pricing model</li>
            </ul>
            <a button rel="noopener noreferrer" href="https://blocksquare.typeform.com/to/BsDXI1">Get in touch</a>

        </article>
        <article class="card separated">
            <div class="stage">tokenization protocol</div>
            <div class="header">
                <div class="icon">
                    <img src="../assets/products/water-icons-01_spring.svg" alt="Stage 1 Spring Logo">
                </div>
                <h3>Spring</h3>
            </div>
            <div class="features">
                <span class="title">Structure & Digitize</span>
                <p>Link real estate to property smart contracts.</p>
            </div>
            <div class="price">
                <button data-variant="outline" routerLink="/pricing" fragment="stages">See pricing</button>
            </div>
        </article>
    </div>
</section>

<section id="revenue-tokenization" background="1">
    <div class="wrapper" data-layout="horizontal">
        <article class="scheme">
            <img src="../../assets/products/tokenization-protocol/stage1/building-revenues.svg" alt="Digitize">
        </article>
        <article>
            <header>
                <label>Transferring value</label>
                <h2>A revenue-based asset tokenization protocol.</h2>
            </header>
            <p>In terms of commercial real estate, the revenues a property generates highly impact the valuation of the property as investors look predominantly for a target return.</p>
            <p>In the real estate context it is also fairly easy to estimate the revenue a particular property can generate. Most common revenue streams for real estate properties are:</p>
            <ul list-style="checked">
                <li>cash flows from lease contracts, and</li>
                <li>sale of the property to a 3rd party buyer</li>
            </ul>
            <button data-variant="outline" routerLink="/learn" fragment="sample">Learn more</button>
        </article>
    </div>
</section>

<section id="case-study" background="3">
    <div class="wrapper" data-layout="top">
        <header>
            <label>Case study</label>
            <h2>The PropToken™ tokenization model in practice.</h2>
        </header>
        <article>
            <p>A property owner has invested in an office building valued at 10,000,000 USD. The equity stake is 2,000,000 USD, a REIT (real estate investment trust) partner has also invested 2,000,000 USD, while a bank loan is in place to finance the remaining 6,000,000 USD over a period of 10 years. All combined lease contracts are projected to generate 2,000,000 USD per year if fully occupied, and the issuer is willing to give up 60% of these projected revenues, as they project 40% need to be reserved for direct and indirect costs.</p>
            <p>The property owner and REIT partner decide to tokenize 20% of the property to decrease their exposure and use funds for a new investment. The previously described tokenization protocol is used, meaning 20,000 tokens are minted from the PropToken contract (out of a maximum possible supply of 100,000 tokens).</p>
        </article>
        <article>
            <p>If revenues perform as projected, token holders will receive 2,000,000 USD ✕ 60% ✕ 20,000 tokens / 100,000 tokens = 240,000 USD, converted into DAI and distributed to token holders each month. Simply put, if you hold 1,000 tokens on your wallet, you receive 1% of the agreed upon royalties.</p>
            <p>The sale price of property tokens is determined by the issuer. While it will not change the amount of royalties token holders will receive, it will affect the profitability for investors.</p>
            <a button rel="noopener noreferrer" href="https://blocksquare.typeform.com/to/BsDXI1">Get in touch</a>
        </article>
    </div>
</section>
