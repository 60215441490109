<section id="intro">
    <div class="wrapper">
        <article>
            <h1>Our real estate tokenization knowledge base</h1>
        </article>
    </div>
    <div class="background"></div>
    <div class="silhuette"></div>
</section>

<section id="map">
    <div class="wrapper" data-layout="grid">
        <article class="card separated">
            <div class="details">
                <div class="header">
                    <h3>Tokenization</h3>
                </div>
                <p>Scalable tokenization protocol for real estate assets.</p>
                <div class="price">
                    <button data-variant="outline" routerLink="/learn/tokenization" fragment="">Learn more</button>
                </div>
            </div>
            <div class="features">
                <div class="icon">
                    <img src="../assets/learn/propositions/icon-03.svg" alt="Tokenization icon, a building with digital connections">
                </div>
            </div>
        </article>

        <article class="card separated">
            <div class="details">
                <div class="header">
                    <h3>Blockchain</h3>
                </div>
                <p>Blockchain tokenization protocol for real estate assets.</p>
                <div class="price">
                    <button data-variant="outline" routerLink="/learn/blockchain">Learn more</button>
                </div>
            </div>
            <div class="features">
                <div class="icon">
                    <img src="../assets/learn/intro/learn-icons-blockchain.svg" alt="Blockchain icon, a scheme of different objects connected together">
                </div>
            </div>
        </article>

        <article class="card separated">
            <div class="details">
                <div class="header">
                    <h3>Regulation</h3>
                </div>
                <p>Regulation tokenization protocol for real estate assets.</p>
                <div class="price">
<!--                    <button data-variant="outline" routerLink="/learn/regulation">Learn more</button>-->
                    <button data-variant="outline">Learn more</button>
                </div>
            </div>
            <div class="features">
                <div class="icon">
                    <img src="../assets/learn/intro/learn-icons-regulation.svg" alt="Regulation icon, a law scale">
                </div>
            </div>
        </article>

        <article class="card separated">
            <div class="details">
                <div class="header">
                    <h3>DeFi</h3>
                </div>
                <p>DeFi tokenization protocol for real estate assets.</p>
                <div class="price">
<!--                    <button data-variant="outline" routerLink="/learn/defi">Learn more</button>-->
                    <button data-variant="outline">Learn more</button>
                </div>
            </div>
            <div class="features">
                <div class="icon">
                    <img src="../assets/learn/intro/learn-icons-defi.svg" alt="DeFi icon, different currencies connected together">
                </div>
            </div>
        </article>

        <article class="card separated">
            <div class="details">
                <div class="header">
                    <h3>Company</h3>
                </div>
                <p>Company tokenization protocol for real estate assets.</p>
                <div class="price">
                    <button data-variant="outline" routerLink="/learn/company">Learn more</button>
                </div>
            </div>
            <div class="features">
                <div class="icon">
                    <img src="../assets/learn/intro/learn-icons-company.svg" alt="Company icon, the world with a caesar crown">
                </div>
            </div>
        </article>

        <article class="card separated">
            <div class="details">
                <div class="header">
                    <h3>Newsroom</h3>
                </div>
                <p>Newsroom tokenization protocol for real estate assets.</p>
                <div class="price">
                    <button data-variant="outline" routerLink="/learn/newsroom">Learn more</button>
                </div>
            </div>
            <div class="features">
                <div class="icon">
                    <img src="../assets/learn/intro/learn-icons-newsroom.svg" alt="Newsroom icon, a megaphone">
                </div>
            </div>
        </article>
    </div>
</section>

<section id="propositions" data-layout="vertical">
    <div class="wrapper">
        <header>
            <h2>Blocksquare's value propositions</h2>
        </header>
        <article>
            <div class="wrapper" data-layout="grid icon-list-h">
                <div><img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>Designed exclusively for commercial real estate</div>
                <div><img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>Open your assets to a global digital economy</div>
                <div><img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>Hold global real estate investments digitally</div>
                <div><img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>Technology enabler for established companies</div>
                <div><img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>Increase value of real estate in your portfolio</div>
                <div><img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>Monitor your portfolio on any device</div>
                <div><img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>Plug-and-play tokenization protocol</div>
                <div><img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>Match available demand even in tier III cities</div>
                <div><img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>Reduce transaction costs of expensive middlemen</div>
                <div><img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>Distribute rent dividends to 100s of beneficiaries</div>
                <div><img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>APIs for the tech-savvy, GUI for everyone else</div>
                <div><img src="../../assets/app/icon/check-mark.svg" alt="Check mark icon"/>Dedicated marketplace for tokenized properties</div>
            </div>
        </article>
    </div>
</section>
