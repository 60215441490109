<section id="intro" background="3">
    <div class="wrapper" data-layout="big">
        <article>
            <header>
                <label>Transparent fees</label>
                <h1>Pricing created for businesses of all sizes.</h1>
            </header>
            <p>Access a complete out-of-the-box real estate tokenization platform with simple, pay-as-you-grow pricing. Available for businesses with large internation real estate portfolios or boutique investment clubs catering local investment models.</p>
            <button data-variant="outline" routerLink="/pricing" fragment="stages">Learn more</button>
        </article>
        <article class="scheme">
        </article>
    </div>
</section>

<section id="stages">
    <div class="wrapper" data-layout="no-overflow">
        <article class="card" id="window"></article>
        <article class="card separated no-background">
            <div class="stage">Tokenization protocol</div>
            <div class="header">
                <div class="icon">
                    <img src="../assets/products/water-icons-01_spring.svg" alt="Stage 1 Spring Logo">
                </div>
                <h3>Spring</h3>
            </div>
            <div class="features">
                <span class="title">Structure & Digitize</span>
                <p>Scalable tokenization protocol for real estate assets.</p>
            </div>
            <div class="price">
                <span class="amount">5,900 EUR</span>
                <span class="description">each real estate asset</span>
            </div>
        </article>

        <article class="card separated no-background">
            <div class="stage">White-label platform</div>
            <div class="header">
                <div class="icon">
                    <img src="../assets/products/water-icons-02_delta.svg" alt="Stage 2 Delta Logo">
                </div>
                <h3>Delta</h3>
            </div>
            <div class="features">
                <span class="title">Offer & Distribute</span>
                <p>Your tokenized real estate investment marketplace.</p>
            </div>
            <div class="price">
                <a button rel="noopener noreferrer" href="https://blocksquare.typeform.com/to/BsDXI1">Send Inquiry</a>
            </div>
        </article>

        <article class="card separated no-background">
            <div class="stage">White-label platform</div>
            <div class="header">
                <div class="icon">
                    <img src="../assets/products/water-icons-03_tide.svg" alt="Stage 3 Tide Logo">
                </div>
                <h3>Tide</h3>
            </div>
            <div class="features">
                <span class="title">Monitor & Report</span>
                <p>Enable trading, distribute revenues and send investor reports.</p>
            </div>
            <div class="price">
                <a button rel="noopener noreferrer" href="https://blocksquare.typeform.com/to/BsDXI1">Send Inquiry</a>
            </div>
        </article>

        <article class="card separated no-background">
            <div class="stage">White-label platform</div>
            <div class="header">
                <div class="icon">
                    <img src="../assets/products/water-icons-04_wave.svg" alt="Stage 4 Wave Logo">
                </div>
                <h3>Wave</h3>
            </div>
            <div class="features">
                <span class="title">Tender & Buyback</span>
                <p>Let issuers repurchase real estate tokens from investors.</p>
            </div>
            <div class="price">
                <a button rel="noopener noreferrer" href="https://blocksquare.typeform.com/to/BsDXI1">Send Inquiry</a>
            </div>
        </article>
    </div>

</section>

<section id="faq" background="5">
    <div class="wrapper" data-layout="top">
        <header>
            <h2>Frequent asked questions.</h2>
        </header>
        <article>
            <label>Is there a setup fee?</label>
            <p>Yes. We charge a one-off payment to set up your platform according to your branding, language and currency.</p>
            <label>How long are your contracts?</label>
            <p>Even though our contractual agreements can be cancelled at any moment, we aim to work with clients on a long-term commitment when territorial exclusivity applies.</p>
            <label>Is it possible to enter into an exclusive collaboration?</label>
            <p>We take the liberty to evaluate the potential of each collaboration, and if deemed appropriate, we may consider signing an exclusive agreement for a specific country or region.</p>
            <label>Do you offer any discounts?</label>
            <p>Bulk or volume discounts are offered based on your project needs. Benefits are offered for projects based on their growth measured by the number of listed properties. Additional cost savings are offered to projects who aim to list more properties right from the start and pre-order a larger amount of “Spring” protocol executions.</p>
            <label>Can I add more properties later?</label>
            <p>Absolutely! You can purchase additional protocol executions at any time.</p>
            <label>Is Blocksquare regulatory compliant?</label>
            <p>Our products have built-in features so you can launch a compliant marketplace, however, you should expect local regulators to verify if the platform adheres to all requirements. Our team can help you in this process.</p>
            <label>Are there any transaction fees?</label>
            <p>Yes, token transaction fees are distributed amongst network stakeholders. At each transaction a fee of 1.5% tokens is deducted and credited to network stakeholders. Fees are always paid by the buyer and executed in property tokens, e.g. if a transaction involves 10,000 tokens, the buyer will receive 10,000 - 1.5% tokens = 9,850 tokens on their wallet even if tokens are transferred outside the platform. The fee distribution to beneficiaries is automated (0.5% goes to the platform operator, 0.5% goes to the issuing company, 0.5% goes to Blocksquare).</p>
        </article>
        <article>
            <label>Can I launch a platform and offer tokenization to other companies?</label>
            <p>Absolutely! In fact, we encourage our clients to think beyond the tokenization of their own real estate investment deals.</p>
            <label>Is it possible to use tokenization to finance real estate development projects?</label>
            <p>In short, yes. Our protocol enables the tokenization of any real estate asset. It is possible to issue tokens backed by land, build-to-sell or build-to-rent real estate.</p>
            <label>Do I need to tokenize 100% of each real estate property?</label>
            <p>No. You can tokenize real estate partially or in full. Our real estate tokenization protocol provisions for tokens to live alongside the more traditional layers in the capital stack like equity and/or debt.</p>
            <label>How many tokens can I issue for each property?</label>
            <p>The issuance of 100,000 tokens are provisioned for each individual real estate property. Each 1% of the capital stack is represented by 1,000 tokens.</p>
            <label>Can I use my own domain name?</label>
            <p>From a marketing perspective, your website visitors will lend on your domain, while the white-label platform is hosted on our side and users will be able to access your marketplace through the url dashboard.blocksquare.io/yourcompany/</p>
            <label>Do you have an API?</label>
            <p>Yes. Please contact us if you would like to build a custom solution on top of our infrastructure.</p>
        </article>

    </div>
</section>

<section id="timeline" background="1">
    <div class="wrapper" data-layout="vertical">
        <header>
            <h2>Start tokenizing today, launch your platform in weeks.</h2>
        </header>
        <div class="wrapper" data-layout="no-overflow">
            <section data-layout="spacing">
                <article>
                    <h3>T - 2w</h3>
                    <ul>
                        <li>Submit real estate portfolio documentation</li>
                        <li>Set factory smart contract</li>
                        <li>Create property smart contracts</li>
                        <li>Sign corporate resolutions for assets</li>
                    </ul>
                </article>

                <article>
                    <h3>T - 1w</h3>
                    <ul>
                        <li>Publish tokenized properties</li>
                        <li>Launch promotional website</li>
                        <li>Prepare legal documentation</li>
                        <li>Reach-out to investors</li>
                    </ul>
                </article>

                <article>
                    <h3>T</h3>
                    <ul>
                        <li>Handout platform to client</li>
                        <li>Client team training</li>
                        <li>Official launch</li>
                        <li>Onboard investors</li>
                    </ul>
                </article>

                <article>
                    <h3>T + ... m</h3>
                    <ul>
                        <li>Upload performance reports</li>
                        <li>Distribute net profits</li>
                        <li>Enable secondary market trading</li>
                        <li>Organize token buyback [optional]</li>
                    </ul>
                    <div class="future-line"></div>
                </article>
            </section>
        </div>
    </div>
</section>
